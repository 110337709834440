import styled from 'styled-components'
import { themeGet } from 'styled-system'

const ResumeLink = styled.a`
  background: ${themeGet('colors.buttons.resumeBackground')};
  border-color: 2px solid ${themeGet('colors.buttons.resumeBackground')};
  color: ${themeGet('colors.buttons.secondaryFont')};
  padding: 0.63rem 0 0.63rem;
  border-radius: ${themeGet('buttons.borderRadius')};
  min-width: 110px;
  font-family: ${themeGet('fontFamilySemibold')};
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  text-decoration: none;

  h5 {
    color: ${themeGet('colors.buttons.secondaryFont')};
  }

  :disabled {
    background: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
    border-color: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
  }

  &:not([disabled]):hover {
    background: ${themeGet('colors.buttons.resumeBackgroundHover')};
    border-color: ${themeGet('colors.buttons.resumeBackgroundHover')};
  }
`

export default ResumeLink
