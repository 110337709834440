/* istanbul ignore file */
// TODO [LEARN-15811]: ^ ignore coverage on this file until we can have app test scaffolding ready
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { RouteContentGetter } from 'layers/content/v2/Hocs/RouteContentGetter'
import { navigationEvent } from 'layers/navigation/store/operations'
import withApiError from 'layers/errorHandling/apiError'
import { userSegmentationBuilder } from 'utils/userSegmentationHelpers'
import { RouteContentProductAppMap } from 'utils/productMaps'
import {
  AI_HS_PAGE_USAGE_KEY,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelpers'
import { isPreviewMode } from 'utils/highschoolHelpers'

import Component from './component'

const HsIndividualResourceContainer = props => {
  const { routeContent, userSegmentationInfo, navigationEvent } = props || {}
  const { content = {}, loading } = routeContent
  const { learningExperience } = content

  const readyToLoad = !!content && !loading

  useEffect(() => {
    sendApplicationInsightsEvent(AI_HS_PAGE_USAGE_KEY, userSegmentationInfo)
  }, [content])

  return (
    readyToLoad && (
      <Component
        entry={learningExperience}
        isPreview={isPreviewMode()}
        navigationEvent={navigationEvent}
      />
    )
  )
}

HsIndividualResourceContainer.propTypes = {
  routeContent: PropTypes.shape({
    content: PropTypes.shape({
      learningExperience: PropTypes.object,
    }),
    loading: PropTypes.bool,
  }),
  userSegmentationInfo: PropTypes.object,
}

const mapStateToProps = (state, _) => ({
  userSegmentationInfo: userSegmentationBuilder(state),
})

const mapDispatchToProps = {
  navigationEvent,
}

const options = {
  app: RouteContentProductAppMap.learnHsProduct,
}

const HydratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HsIndividualResourceContainer)

export default RouteContentGetter(withApiError(HydratedComponent), options)
