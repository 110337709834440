import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BodyCopy,
  ContainedButton,
  HeaderFive,
  HeaderThree,
  Modal,
  ModalContentWrapper,
} from 'secondstep-components'

import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'

export const TitleSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const StyledModal = styled(Modal)`
  ${ModalContentWrapper} {
    box-sizing: border-box;
    width: ${props => (props.wide ? '750px' : '650px')};
    max-height: 100%;
    overflow: auto;
    padding: 2.25rem 1.75rem 1.25rem 1.75rem;
  }
`

export const Title = styled(HeaderThree)`
  text-align: center;
  margin-bottom 0.625rem;
`
export const CenteredSubTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: ${themeGet('font.molde.regular')};
  text-align: center;
`

export const SiteName = styled(HeaderFive)`
  text-align: center;
  font-size: 1.0rem
  margin-bottom: 0;
`
export const SettingName = styled.span`
  text-align: center;
`

export const Status = styled.p`
  background-color: ${themeGet('colors.gray200')};
  border-radius: 0.5rem;
  font-family: ${themeGet('font.molde.regular')};
  padding: 1.5625rem 0 1.25rem 0;
  margin-bottom: 1.75rem;
  text-align: center;
  width: 100%;
`

export const StyledRadioButtonsContainer = styled.div`
  justify-content: center;
  display: flex;
  gap: 1.25rem;
`

export const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.25rem;
`

export const CancelButton = styled(ContainedButton)`
  background: ${themeGet('colors.buttons.startBackground')};
  border-color: ${themeGet('colors.buttons.startBackground')};
  color: ${themeGet('colors.buttons.primaryFont')};

  &:not([disabled]):hover {
    background: ${themeGet('colors.buttons.startBackgroundHover')};
    border-color: ${themeGet('colors.buttons.startBackgroundHover')};
  }
`

export const SaveButton = styled(ContainedButton)`
  background: ${themeGet('colors.buttons.startBackground')};
  border-color: ${themeGet('colors.buttons.startBackground')};
  color: ${themeGet('colors.buttons.primaryFont')};

  &:not([disabled]):hover {
    background: ${themeGet('colors.buttons.startBackgroundHover')};
    border-color: ${themeGet('colors.buttons.startBackgroundHover')};
  }
`

export const StyledBodyCopy = styled(BodyCopy)`
  padding-right: 0.375rem;
`

export const StyledLabel = styled.label`
  margin-top: 0.9375rem;
`

export const ButtonAndLabel = styled.div`
  display: flex;
  align-items: center;
`
