import React from 'react'
import { CompleteIcon, IncompleteIcon } from 'secondstep-components'

import ResumeButton from 'src/components/ResumeButton'
import ReviewButton from 'src/components/ReviewButton'
import StartButton from 'src/components/StartButton'

import ResumeLink from 'src/components/ResumeLink'
import ReviewLink from 'src/components/ReviewLink'
import StartLink from 'src/components/StartLink'

const LABELS = {
  NOTSTARTED: 'Not Started',
  INPROGRESS: 'In Progress',
  COMPLETE: 'Complete',
}

const ICONS = {
  INCOMPLETE: <IncompleteIcon />,
  COMPLETE: <CompleteIcon />,
}

export const buttonMap = {
  'not attempted': {
    Button: StartButton,
    icon: ICONS.INCOMPLETE,
    text: LABELS.NOTSTARTED,
  },
  incomplete: {
    Button: ResumeButton,
    icon: ICONS.INCOMPLETE,
    text: LABELS.INPROGRESS,
  },
  completed: {
    Button: ReviewButton,
    icon: ICONS.COMPLETE,
    text: LABELS.COMPLETE,
  },
}

export const linksMap = {
  'not attempted': {
    Button: StartLink,
    icon: ICONS.INCOMPLETE,
    text: LABELS.NOTSTARTED,
  },
  incomplete: {
    Button: ResumeLink,
    icon: ICONS.INCOMPLETE,
    text: LABELS.INPROGRESS,
  },
  completed: {
    Button: ReviewLink,
    icon: ICONS.COMPLETE,
    text: LABELS.COMPLETE,
  },
}
