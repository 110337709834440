/* istanbul ignore file */
export const LOADING_SELA_MESSAGE = 'Loading program modules...'
export const PROGRAM_ELEMENTARY = 'elementary digital'
export const PROGRAM_FIELD_TEST = 'sela field test program'
export const PROGRAM_FAMILY = 'family'
export const PROGRAM_HIGH_SCHOOL_FAMILY = 'highschool program - families'
export const PROGRAM_HS = 'hs program'
export const PROGRAM_K8_DIGITAL = 'k8 digital programs'
export const PROGRAM_MIND_YETI = 'mind yeti'
export const PROGRAM_MS = 'middle school'
export const PROGRAM_SELA = 'sela program'
export const SUBHEADER_KIT = 'My Kits'
export const SUBHEADER_TEACH = 'My Classes'
export const PROGRAM_OST = 'ost'
export const SUBHEADER_OST = 'Activities'
