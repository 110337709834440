import React from 'react'
import PropTypes from 'prop-types'
import { ScheduleWrapper, ScheduleCopy } from './component.styles'
import {
  ConfirmButton,
  CancelButton,
  ButtonWrapper,
  ModalHeader,
} from '../component.styles'
import PacingDate from 'components/PacingDate'
import PacingSchedule from 'components/PacingSchedule'
import { addDays, addWeeks } from './data'

const ReviewPacing = ({
  handleContinueButtonClick,
  handleClose,
  data,
  implementationLevel,
  unmodifiedDates,
}) => {
  const { bodyCopy, header, confirmLabel, content, editLabel } = data || {}
  const [pathway1Data, pathway2Data, pathway3Data, pathway4Data] = content || []
  const {
    programStartDate,
    schoolYearEndDate,
    trainingStartDate,
    node1,
    node4,
    node7,
    node10,
    node11,
    node12,
  } = unmodifiedDates
  const isImplementationLevel1 = implementationLevel === 1
  const weeksToAdd = isImplementationLevel1 ? 3 : 2
  const firstEndDate = addDays(node4, -1)
  const secondStartDate = node4
  const secondEndDate = addDays(node7, -1)
  const thirdStartDate = node7
  const thirdEndDate = addDays(node10, -1)
  const fourthStartDate = node10
  const fourthEndDate = isImplementationLevel1
    ? addWeeks(node11, weeksToAdd)
    : addWeeks(node12, weeksToAdd)

  return (
    <ScheduleWrapper>
      <ModalHeader>{header}</ModalHeader>
      <ScheduleCopy>{bodyCopy}</ScheduleCopy>
      <PacingSchedule
        programStartDate={programStartDate}
        schoolYearEndDate={schoolYearEndDate}
        trainingStartDate={trainingStartDate}
      />
      <PacingDate
        endDate={firstEndDate}
        startDate={node1}
        subtitle={pathway1Data.headerSecondary}
        title={pathway1Data.header}
      />
      <PacingDate
        endDate={secondEndDate}
        startDate={secondStartDate}
        subtitle={pathway2Data.headerSecondary}
        title={pathway2Data.header}
      />
      <PacingDate
        endDate={thirdEndDate}
        startDate={thirdStartDate}
        subtitle={pathway3Data.headerSecondary}
        title={pathway3Data.header}
      />
      <PacingDate
        endDate={fourthEndDate}
        startDate={fourthStartDate}
        subtitle={pathway4Data.headerSecondary}
        title={pathway4Data.header}
      />
      <ButtonWrapper>
        <CancelButton onClick={() => handleContinueButtonClick()}>
          {editLabel}
        </CancelButton>
        <ConfirmButton onClick={() => handleClose()}>
          {confirmLabel}
        </ConfirmButton>
      </ButtonWrapper>
    </ScheduleWrapper>
  )
}

ReviewPacing.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func,
  handleContinueButtonClick: PropTypes.func,
  implementationLevel: PropTypes.number,
  unmodifiedDates: PropTypes.object,
}

export default ReviewPacing
