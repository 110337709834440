import React from 'react'
import PropTypes from 'prop-types'
import FirstTimeClasses from 'experienceComponents/FirstTimeClasses'
import K8DigitalProgramCard from 'components/dashboard/K8DigitalProgramCard'

const ProductClasses = ({
  isK5Product,
  isMsProduct,
  classes,
  contentfulCourses,
  currentProgramName,
  displayTitle,
  entryId,
  firstTimeMS,
  headerDataTestId,
  index,
  internalTitle,
  onClassBlur,
  onClassFocus,
  onComponentClick,
  programSubheader,
  setFocus,
  staticLinksAvailable,
  classMenuButtonRefs,
}) => (
  <>
    {(isK5Product || isMsProduct) && entryId && (
      <FirstTimeClasses
        contentfulCourses={contentfulCourses}
        entryId={entryId}
        onComponentClick={onComponentClick}
        show={firstTimeMS}
      />
    )}
    {isK5Product && classes.length > 0 && (
      <K8DigitalProgramCard
        classMenuButtonRefs={classMenuButtonRefs}
        classes={classes}
        contentfulCourses={contentfulCourses}
        currentProgramName={currentProgramName}
        displayTitle={displayTitle}
        headerDataTestId={headerDataTestId}
        index={index}
        internalTitle={internalTitle}
        isK5Product={true}
        onClassBlur={onClassBlur}
        onClassFocus={onClassFocus}
        onComponentClick={onComponentClick}
        programSubheader={programSubheader}
        setFocus={setFocus}
        staticLinksAvailable={staticLinksAvailable}
      />
    )}
    {isMsProduct && classes.length > 0 && (
      <K8DigitalProgramCard
        classMenuButtonRefs={classMenuButtonRefs}
        classes={classes}
        contentfulCourses={contentfulCourses}
        currentProgramName={currentProgramName}
        displayTitle={displayTitle}
        headerDataTestId={headerDataTestId}
        index={index}
        internalTitle={internalTitle}
        isMsProduct={true}
        onClassBlur={onClassBlur}
        onClassFocus={onClassFocus}
        onComponentClick={onComponentClick}
        programSubheader={programSubheader}
        setFocus={setFocus}
        staticLinksAvailable={staticLinksAvailable}
      />
    )}
  </>
)

ProductClasses.propTypes = {
  classMenuButtonRefs: PropTypes.object,
  classes: PropTypes.array.isRequired,
  contentfulCourses: PropTypes.array.isRequired,
  currentProgramName: PropTypes.string.isRequired,
  displayTitle: PropTypes.string.isRequired,
  entryId: PropTypes.string,
  firstTimeMS: PropTypes.bool,
  headerDataTestId: PropTypes.string,
  index: PropTypes.number,
  internalTitle: PropTypes.string.isRequired,
  isK5Product: PropTypes.bool.isRequired,
  isMsProduct: PropTypes.bool.isRequired,
  onClassBlur: PropTypes.func.isRequired,
  onClassFocus: PropTypes.func.isRequired,
  onComponentClick: PropTypes.func.isRequired,
  programSubheader: PropTypes.string,
  setFocus: PropTypes.func.isRequired,
  staticLinksAvailable: PropTypes.array.isRequired,
}

export default ProductClasses
