import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderFive } from 'secondstep-components'

export const Wrapper = styled(Box)`
  position: relative;
  flex-direction: column;
  width: 210px;
  border-radius: 0.9375rem;
  text-transform: none;

  span {
    margin: 0;
    text-align: left;
  }

  a {
    position: relative;
    padding-top: 1.499rem;
    padding-right: 1.425rem;
    padding-left: 0.8125rem;
    padding-bottom: 1.375rem;
    border-radius: 0.9375rem;
    text-align: left;
    text-transform: none;
    margin-left: 0.875rem;
    width: 100%;
  }

  ${HeaderFive} {
    margin: 0;
    font-size: 1.375rem;
  }

  svg {
    position: absolute;
    top: 0.875rem;
    right: 0.875rem;
    color: ${themeGet('colors.iconBlue')};
  }

  ${themeGet('breakpoints.tablet')} {
    width: 153px;
  }

  ${themeGet('breakpoints.mobile')} {
    width: 100%;

    ${HeaderFive} {
      word-spacing: normal;
    }
  }
`
