import ResumeLink from 'src/components/ResumeLink'
import ReviewLink from 'src/components/ReviewLink'
import StartLink from 'src/components/StartLink'

export default {
  'not attempted': {
    Button: StartLink,
    text: 'START',
  },
  incomplete: {
    Button: ResumeLink,
    text: 'RESUME',
  },
  completed: {
    Button: ReviewLink,
    text: 'REVIEW',
  },
}
