import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Link } from 'react-router-dom'

import {
  BodyCopy,
  HeaderOne,
  HeaderTwo,
  HeaderThree,
  HeaderFour,
} from 'secondstep-components'

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block; /* Ensures the link behaves like a div if needed */
`

export const PageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${themeGet('colors.highschool.green0')};
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: #fff;
  align-items: center;
`

export const HeaderText = styled(HeaderOne)`
  margin-bottom: 1rem;
  text-align: center;
  font-size: 2.625rem;
  font-weight: 800;
  letter-spacing: 1.11px;
  line-height: 2.875rem;
  font-family: Komet;
`

export const HeaderDescription = styled.p`
  font-size: 1.125rem;
  margin-bottom: 2rem;
`

export const ContentContainer = styled.div`
  position: relative;
  bottom: 3rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1.5px solid ${themeGet('colors.highschool.green3')};
  border-radius: 0.75rem;
  background-color: ${themeGet('colors.linkLight')};
  width: 94%;
  align-self: center;
  padding: 0 1rem;
  max-width: 82.625rem;
`

export const ContentHeader = styled(HeaderTwo)`
  margin: 1.5rem 0;
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: 0.57px;
  line-height: 2.125rem;
`

export const StyledBodyCopy = styled(BodyCopy)`
  a {
    color: ${themeGet('colors.highschool.pink1')};
    text-decoration: none;
  }
  margin-bottom: 2rem;
`
export const CardContentContainer = styled.div``

export const CardBodyContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  gap: 1rem;
  padding: 1rem 0;
  margin-bottom: 1.5rem;
  ${themeGet('breakpoints.medium')} {
    grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
  }
`

export const CardHeaderH3 = styled(HeaderThree)`
  font-size: 1.25rem;
`

export const CardHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  img {
    height: 3.125rem;
    width: 3.125rem;
    margin-right: 0.5rem;
    padding-bottom: 0.5rem;
  }
`

export const CardBodyHeader = styled(HeaderFour)`
  padding-left: 1rem;
  padding-top: 1rem;
  margin-bottom: 0;
  font-weight: 600;
`

export const CardBodyContent = styled.div`
  box-sizing: border-box;
  height: 7.063rem;
  width: 100%;
  border: 2px solid rgba(103, 127, 26, 0.35);
  border-radius: 0.625rem;
  background-color: ${themeGet('colors.highschool.green0')};
  cursor: pointer;
  // because of the layout of the card, padding is counted from the top of the card:
  padding-top: 0.1rem;
  padding-bottom: 7.2rem;
  ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin-block-start: 0.75rem;
  }

  &:hover {
    border-color: ${themeGet('colors.highschool.green1')};
  }
`

export const MetadataContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 0.5rem;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  img {
    padding-bottom: 0.22rem;
  }
`
