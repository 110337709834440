import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AdminTrainingEmptyStateIcon } from 'secondstep-components'
import { TOGGLE_VIEW } from 'store/userSessionManager/constants'
import { TOOLTIP_LOCATIONS, TOOLTIP_PROGRAMS } from 'utils/tooltipHelpers'
import AccessibleTextLink from 'components/AccessibleTextLink'
import AdminDonutChart from 'components/AdminDonutChart'
import AdminPathwayProgress from 'components/AdminPathwayProgress'
import {
  ChartsWrapper,
  EmptyStateMessage,
  EmptyStateWrapper,
  HeaderWrapper,
  LastUpdatedMessage,
  LeftBox,
  MainTitle,
  ModalBodyCopy,
  ModalCloseButton,
  ModalHeader,
  MonitorSnapshotWrapper,
  OverallProgressHeaderWrapper,
  OverallProgressTitle,
  OverallProgressWrapper,
  PathwayAndProgressWrapper,
  RightBox,
  StyledAdminExportDownloadButton,
  StyledAdminTooltip,
  StyledBodyCopy,
  StyledClosableModal,
  StyledDonutHairline,
  StyledHairline,
} from './component.styles'
import {
  EMPTY_STATE_MESSAGE,
  MAIN_DESCRIPTION_TEXT,
  MAIN_TITLE_TEXT,
  MODAL_CLOSE_BUTTON_TEXT,
  MODAL_LINK_TEXT,
  OVERALL_PROGRESS_TITLE_TEXT,
  PROGRAM_NAME_EDUCATOR_PRACTICES,
  PROGRAM_NAME_SCHOOLWIDE_PRACTICES,
  PROGRAM_NAME_STUDENT_ACTIVITIES,
} from './constants'
import { formatHoursSinceTimestamp } from 'utils/reportsHelpers'

const AdminHsMonitorSnapshot = ({
  isMultiSite,
  lastUpdatedAt,
  modalText,
  modalTitle,
  site,
}) => {
  const [showModal, setShowModal] = useState(false)
  const {
    educatorPracticesPathways,
    preferences,
    schoolwidePracticesPathways,
    siteId,
    studentActivitiesByGrade,
  } = site || {}
  const { studentActivities: isOptedInStudentActivties } = preferences || {}
  const lastUpdatedMessage = formatHoursSinceTimestamp(lastUpdatedAt)
  const isPacingPathway = site?.currentPacingName?.includes('Pathway')
  const isProgressMade =
    educatorPracticesPathways?.totalPracticesOrActivitiesCompleted > 0 ||
    schoolwidePracticesPathways?.totalPracticesOrActivitiesCompleted > 0 ||
    studentActivitiesByGrade?.totalPracticesOrActivitiesCompleted > 0

  return (
    <MonitorSnapshotWrapper isMultiSite={isMultiSite}>
      <HeaderWrapper isMultiSite={isMultiSite}>
        <LeftBox>
          <MainTitle>{MAIN_TITLE_TEXT}</MainTitle>
          <StyledBodyCopy>{MAIN_DESCRIPTION_TEXT}</StyledBodyCopy>
        </LeftBox>
        <RightBox>
          <LastUpdatedMessage>{lastUpdatedMessage}</LastUpdatedMessage>
          <StyledAdminExportDownloadButton
            programKey={TOOLTIP_PROGRAMS.HS}
            toggleView={TOGGLE_VIEW.MONITOR}
            tooltipLocation={TOOLTIP_LOCATIONS.EXPORT_BUTTON}
          />
        </RightBox>
      </HeaderWrapper>
      {!isPacingPathway && !isProgressMade && (
        <EmptyStateWrapper isMultiSite={isMultiSite}>
          <AdminTrainingEmptyStateIcon />
          <EmptyStateMessage>{EMPTY_STATE_MESSAGE}</EmptyStateMessage>
        </EmptyStateWrapper>
      )}
      {(isPacingPathway || isProgressMade) && (
        <PathwayAndProgressWrapper>
          <OverallProgressWrapper isMultiSite={isMultiSite}>
            <OverallProgressHeaderWrapper>
              <OverallProgressTitle>
                {OVERALL_PROGRESS_TITLE_TEXT}
              </OverallProgressTitle>
              <StyledAdminTooltip
                location={TOOLTIP_LOCATIONS.OVERALL_PROGRESS}
                programKey={TOOLTIP_PROGRAMS.HS_MONITOR}
              />
              <AccessibleTextLink
                ariaLabel="how-are-these-calculated-modal-link"
                dataTestId="how-are-these-calculated-modal-link"
                onClick={() => setShowModal(true)}
              >
                {MODAL_LINK_TEXT}
              </AccessibleTextLink>
            </OverallProgressHeaderWrapper>
            <StyledHairline />
            <ChartsWrapper>
              <AdminDonutChart
                data={schoolwidePracticesPathways}
                programName={PROGRAM_NAME_SCHOOLWIDE_PRACTICES}
              />
              <StyledDonutHairline />
              <AdminDonutChart
                data={educatorPracticesPathways}
                programName={PROGRAM_NAME_EDUCATOR_PRACTICES}
              />
              <StyledDonutHairline />
              <AdminDonutChart
                data={studentActivitiesByGrade}
                isOptedInStudentActivties={isOptedInStudentActivties}
                programName={PROGRAM_NAME_STUDENT_ACTIVITIES}
              />
            </ChartsWrapper>
          </OverallProgressWrapper>
          <AdminPathwayProgress siteId={siteId} />
        </PathwayAndProgressWrapper>
      )}
      <StyledClosableModal
        onClose={() => setShowModal(false)}
        show={showModal}
        showClose
      >
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBodyCopy>{modalText}</ModalBodyCopy>
        <ModalCloseButton onClick={() => setShowModal(false)}>
          {MODAL_CLOSE_BUTTON_TEXT}
        </ModalCloseButton>
      </StyledClosableModal>
    </MonitorSnapshotWrapper>
  )
}

AdminHsMonitorSnapshot.propTypes = {
  isMultiSite: PropTypes.bool,
  lastUpdatedAt: PropTypes.string,
  modalText: PropTypes.string,
  modalTitle: PropTypes.string,
  site: PropTypes.object, // TODO: define props needed for this component
}

export default AdminHsMonitorSnapshot
