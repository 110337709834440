export const COMPLETE = 'Complete'
export const COMPLETED_TRAINING = 'Completed Training'
export const INCOMPLETE = 'Incomplete'
export const IN_PROGRESS = 'In Progress'
export const KICKOFF_TRAINING_LINK_TEXT = 'Go to Kickoff Training'
export const KICKOFF_TRAINING_PATH =
  '/product/high-school/training/program-kick-off?mode=teach'
export const MANAGE_USERS_LINK_TEXT = 'Manage Users'
export const MANAGE_USERS_URL = '/user-management'
export const NOT_STARTED = 'Not Started'
export const PACING_SCHEDULE_LINK_TEXT = 'Pacing Schedule'
export const SCHOOL_SETTINGS_TEXT = 'School Settings'
export const STAFF_KICKOFF_HEADER_TEXT = 'Staff Kick-Off'
export const STAFF_TRAINING_PROGRESS_HEADER_TEXT = 'Staff Training Progress'
export const USERS_ADDED_TEXT = 'Users Added'
