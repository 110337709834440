import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { HalfCircleProgressBar } from 'secondstep-components'
import { formatHoursSinceTimestamp } from 'utils/reportsHelpers'
import { pluralizeWord } from 'utils/stringHelpers'
import { TOOLTIP_LOCATIONS } from 'utils/tooltipHelpers'
import { domains } from 'config/env'
import NavigationHelper from 'layers/navigation/navigationHelper'
import PacingModal from 'components/PacingModal'
import StepModal from 'components/StepModal'
import {
  COMPLETE,
  COMPLETED_TRAINING,
  INCOMPLETE,
  IN_PROGRESS,
  KICKOFF_TRAINING_LINK_TEXT,
  KICKOFF_TRAINING_PATH,
  MANAGE_USERS_LINK_TEXT,
  MANAGE_USERS_URL,
  NOT_STARTED,
  PACING_SCHEDULE_LINK_TEXT,
  SCHOOL_SETTINGS_TEXT,
  STAFF_KICKOFF_HEADER_TEXT,
  STAFF_TRAINING_PROGRESS_HEADER_TEXT,
  USERS_ADDED_TEXT,
} from './constants'
import {
  ActiveCount,
  BodyBox,
  Bullet,
  Card,
  CompleteTextWrapper,
  CompletedTrainingText,
  CountBox,
  CountRow,
  EditLink,
  InProgressTextWrapper,
  KickOffTrainingLink,
  LastUpdatedMessage,
  LaunchSnapshotBullet,
  LeftBox,
  LevelingBox,
  LevelingIconStyled,
  ManageUsersLink,
  NotStartedTextWrapper,
  PacingBox,
  PacingIconStyled,
  PacingScheduleLink,
  PendingCount,
  PercentageText,
  ProgressBarWrapper,
  ProgressContentWrapper,
  ProgramRolesBox,
  ProgramUsersCompletedText,
  ProgramUsersIncompleteText,
  RightBox,
  RoleUsersText,
  SchoolSettingsBox,
  SchoolSettingsText,
  StaffKickOffStatusText,
  StaffKickOffTextWrapper,
  StaffKickOffWrapper,
  StaffTrainingProgressTextWrapper,
  StaffTrainingProgressWrapper,
  StyledAdminTooltip,
  StyledDataText,
  StyledError,
  StyledHairline,
  StyledHeaderText,
  StyledWarningIcon,
  TotalCount,
  TrainingDataContentWrapper,
  TrainingDataHeader,
  TrainingDataWrapper,
  TrainingHeaderText,
  TrainingHeaderMessage,
  UsersAddedBox,
  UsersAddedCount,
  UsersAddedText,
  UsersCompletedBox,
  UsersCompletionText,
  VerticalHairline,
} from './component.styles'

const HighSchoolLaunchSnapshot = ({
  lastUpdatedAt,
  navigationEvent,
  programKey,
  site,
  sitePreferences,
}) => {
  const [isHighSchoolLaunchedError, setIsHighSchoolLaunchedError] = useState(
    false,
  )
  const [isPacingModalOpen, setPacingModalOpen] = useState(false)
  const [isStepModalOpen, setStepModalOpen] = useState(false)

  const createUserSelectedText = (numOfUsers, type) => {
    const typeTextMap = {
      schoolwide: 'Schoolwide Practices',
      educator: 'Educator Practices',
      student: 'Student Activities',
    }

    return `${numOfUsers} ${pluralizeWord(
      numOfUsers,
      'user',
      'users',
    )} selected ${typeTextMap?.[type]}`
  }

  const navigateTo = url => {
    const isManageUsersUrl = url === MANAGE_USERS_URL
    const type = isManageUsersUrl
      ? NavigationHelper.types.OPEN
      : NavigationHelper.types.PUSH
    const path = `${isManageUsersUrl ? domains.ADMIN_DOMAIN : ''}${url}${
      isManageUsersUrl ? `/${site.siteId}` : ''
    }`

    navigationEvent(path, type)
  }

  const togglePacingModal = () => {
    setPacingModalOpen(!isPacingModalOpen)
  }

  const toggleStepModal = () => {
    setStepModalOpen(!isStepModalOpen)
  }

  useEffect(() => {
    if (sitePreferences.error && sitePreferences.siteId === site.siteId) {
      setIsHighSchoolLaunchedError(true)
    }
  }, [sitePreferences])

  const { schoolTraining, schoolwidePractices, studentActivities } = site || {}
  const {
    number_of_users_with_educator_practices,
    number_of_users_with_schoolwide_practices,
  } = schoolwidePractices || {}
  const { number_of_users_with_student_activities_enabled } =
    studentActivities || {}
  const {
    schoolwide_training_kickoff_status = 'Not Started',
    training_program_completion_percent = 0,
    training_program_users_completed = 0,
    training_program_users_in_progress = 0,
    training_program_users_not_started = 0,
  } = schoolTraining || {}

  const kickoffStatusText =
    schoolwide_training_kickoff_status === 'Complete' ? COMPLETE : INCOMPLETE
  const trainingHeaderText = `${pluralizeWord(
    training_program_users_completed,
    'User',
    'Users',
  )} Completed Training`
  const completedCount = `${training_program_users_completed} ${COMPLETE}`
  const inProgressCount = `${training_program_users_in_progress} ${IN_PROGRESS}`
  const notStartedCount = `${training_program_users_not_started} ${NOT_STARTED}`
  const totalUserCount =
    training_program_users_completed +
    training_program_users_in_progress +
    training_program_users_not_started
  const userCompletionMessage = `${training_program_users_completed} of ${totalUserCount} Users`
  const progressPercentage = Math.floor(
    training_program_completion_percent * 100,
  )

  const lastUpdatedMessage = formatHoursSinceTimestamp(lastUpdatedAt)

  return (
    <BodyBox>
      <LeftBox>
        <UsersAddedBox>
          <UsersAddedCount>{site.totalUsersCount}</UsersAddedCount>
          <UsersAddedText>
            {USERS_ADDED_TEXT}
            <StyledAdminTooltip
              location={TOOLTIP_LOCATIONS.LAUNCH_USERS}
              programKey={programKey}
            />
          </UsersAddedText>
          <StyledHairline />
          <TotalCount>
            {`${site.adminsActiveCount +
              site.adminsPendingCount} Administrators`}
          </TotalCount>
          <CountRow>
            <CountBox>
              <Bullet />
              <ActiveCount>{`${site.adminsActiveCount} active`}</ActiveCount>
            </CountBox>
            <CountBox>
              <Bullet color="yellow" />
              <PendingCount>
                {`${site.adminsPendingCount} pending`}
              </PendingCount>
            </CountBox>
          </CountRow>
          <TotalCount>
            {`${site.teachersActiveCount + site.teachersPendingCount} Teachers`}
          </TotalCount>
          <CountRow>
            <CountBox>
              <Bullet />
              <ActiveCount>{`${site.teachersActiveCount} active`}</ActiveCount>
            </CountBox>
            <CountBox>
              <Bullet color="yellow" />
              <PendingCount>
                {`${site.teachersPendingCount} pending`}
              </PendingCount>
            </CountBox>
          </CountRow>
          <ManageUsersLink onClick={() => navigateTo(MANAGE_USERS_URL)}>
            {MANAGE_USERS_LINK_TEXT}
          </ManageUsersLink>
        </UsersAddedBox>
        <ProgramRolesBox>
          <UsersAddedText>
            Program Role Set-Up{' '}
            <span className="no-wrap">
              Status
              <StyledAdminTooltip
                className="text-7-rows"
                location={TOOLTIP_LOCATIONS.ADMIN_PROGRAM_ROLE_SETUP}
                programKey={programKey}
              />
            </span>
          </UsersAddedText>
          <StyledHairline />
          <ProgramUsersCompletedText>{`${0} Users Completed MOCK`}</ProgramUsersCompletedText>
          <RoleUsersText>
            <Bullet color="yellow" />{' '}
            {createUserSelectedText(
              number_of_users_with_schoolwide_practices,
              'schoolwide',
            )}
          </RoleUsersText>
          <RoleUsersText>
            <Bullet />{' '}
            <span>
              {createUserSelectedText(
                number_of_users_with_educator_practices,
                'educator',
              )}
            </span>
          </RoleUsersText>
          <RoleUsersText>
            <Bullet />{' '}
            {createUserSelectedText(
              number_of_users_with_student_activities_enabled,
              'student',
            )}
          </RoleUsersText>
          <ProgramUsersIncompleteText>{`${0} Incomplete MOCK`}</ProgramUsersIncompleteText>
        </ProgramRolesBox>
      </LeftBox>
      <RightBox>
        <UsersCompletedBox>
          <LastUpdatedMessage>{lastUpdatedMessage}</LastUpdatedMessage>
          <TrainingDataHeader>
            <TrainingHeaderText>
              {training_program_users_completed}
            </TrainingHeaderText>
            <TrainingHeaderMessage>
              {trainingHeaderText}
              <StyledAdminTooltip
                className="text-5-rows"
                location={TOOLTIP_LOCATIONS.ADMIN_USERS_COMPLETED_TRAINING}
                programKey={programKey}
              />
            </TrainingHeaderMessage>
          </TrainingDataHeader>
          <TrainingDataWrapper>
            <StyledHairline />
            <Card>
              <ProgressBarWrapper>
                <HalfCircleProgressBar progress={progressPercentage} />
                <ProgressContentWrapper>
                  <PercentageText>{progressPercentage}%</PercentageText>
                  <CompletedTrainingText>
                    {COMPLETED_TRAINING}
                  </CompletedTrainingText>
                  <UsersCompletionText>
                    {userCompletionMessage}
                  </UsersCompletionText>
                </ProgressContentWrapper>
              </ProgressBarWrapper>
              <VerticalHairline />
              <TrainingDataContentWrapper>
                <StaffKickOffWrapper>
                  <StyledHeaderText>
                    {STAFF_KICKOFF_HEADER_TEXT}
                  </StyledHeaderText>
                  <StaffKickOffTextWrapper>
                    <StaffKickOffStatusText>
                      {schoolwide_training_kickoff_status === 'Complete' && (
                        <LaunchSnapshotBullet />
                      )}
                      {schoolwide_training_kickoff_status !== 'Complete' && (
                        <LaunchSnapshotBullet color="yellow" />
                      )}
                      <StyledDataText>{kickoffStatusText}</StyledDataText>
                    </StaffKickOffStatusText>
                    {schoolwide_training_kickoff_status !== 'Complete' && (
                      <KickOffTrainingLink
                        onClick={() =>
                          navigateTo(
                            `${KICKOFF_TRAINING_PATH}&site=${site.siteId}`,
                          )
                        }
                      >
                        {KICKOFF_TRAINING_LINK_TEXT}
                      </KickOffTrainingLink>
                    )}
                  </StaffKickOffTextWrapper>
                </StaffKickOffWrapper>
                <StaffTrainingProgressWrapper>
                  <StyledHeaderText>
                    {STAFF_TRAINING_PROGRESS_HEADER_TEXT}
                  </StyledHeaderText>
                  <StaffTrainingProgressTextWrapper>
                    <CompleteTextWrapper>
                      <LaunchSnapshotBullet />
                      <StyledDataText>{completedCount}</StyledDataText>
                    </CompleteTextWrapper>
                    <InProgressTextWrapper>
                      <LaunchSnapshotBullet color="yellow" />
                      <StyledDataText>{inProgressCount}</StyledDataText>
                    </InProgressTextWrapper>
                    <NotStartedTextWrapper>
                      <LaunchSnapshotBullet color="gray" />
                      <StyledDataText>{notStartedCount}</StyledDataText>
                    </NotStartedTextWrapper>
                  </StaffTrainingProgressTextWrapper>
                </StaffTrainingProgressWrapper>
              </TrainingDataContentWrapper>
            </Card>
          </TrainingDataWrapper>
        </UsersCompletedBox>
        <SchoolSettingsBox>
          <SchoolSettingsText>
            {SCHOOL_SETTINGS_TEXT}
            <StyledAdminTooltip
              className="text-5-rows"
              location={TOOLTIP_LOCATIONS.ADMIN_SCHOOL_SETTINGS}
              programKey={programKey}
            />
          </SchoolSettingsText>
          <LevelingBox>
            <LevelingIconStyled />
            <EditLink
              onClick={toggleStepModal}
            >{`Level ${site.preferences.implementationLevel} • ${site.preferences.implementationType}`}</EditLink>
          </LevelingBox>
          <PacingBox>
            <PacingIconStyled />
            <PacingScheduleLink onClick={togglePacingModal}>
              {PACING_SCHEDULE_LINK_TEXT}
            </PacingScheduleLink>
          </PacingBox>
        </SchoolSettingsBox>
      </RightBox>
      {isStepModalOpen && (
        <StepModal
          editMode={true}
          handleClose={toggleStepModal}
          programKey={programKey}
          savedPreferences={site.preferences}
          siteId={site.siteId}
        />
      )}
      {isPacingModalOpen && (
        <PacingModal
          handleClose={togglePacingModal}
          programKey={programKey}
          savedPreferences={site.preferences}
          siteId={site.siteId}
        />
      )}
      {isHighSchoolLaunchedError && (
        <StyledError>
          <StyledWarningIcon />
          Something went wrong.
          <br />
          Try again.
        </StyledError>
      )}
    </BodyBox>
  )
}

HighSchoolLaunchSnapshot.propTypes = {
  lastUpdatedAt: PropTypes.string,
  navigationEvent: PropTypes.func,
  programKey: PropTypes.string,
  site: PropTypes.object,
  sitePreferences: PropTypes.object,
}

export default HighSchoolLaunchSnapshot
