import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { ContainedButton } from 'secondstep-components'

const ResumeButton = styled(ContainedButton)`
  background: ${themeGet('colors.buttons.resumeBackground')};
  border-color: ${themeGet('colors.buttons.resumeBackground')};
  color: ${themeGet('colors.buttons.secondaryFont')};

  h5 {
    color: ${themeGet('colors.buttons.secondaryFont')};
  }

  &:not([disabled]):hover {
    background: ${themeGet('colors.buttons.resumeBackgroundHover')};
    border-color: ${themeGet('colors.buttons.resumeBackgroundHover')};
  }
`

export default ResumeButton
