/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React, { useEffect } from 'react'

import { RouteContentGetter } from 'layers/content/v2/Hocs/RouteContentGetter'
import NavigationHelper from 'layers/navigation/navigationHelper'
import withApiError from 'layers/errorHandling/apiError'
import { resolveRouteServiceApp } from 'utils/productHelpers'
import { RouteContentProductAppMap } from 'utils/productMaps'
import { getListProps, formContentCardList, getVideoData } from './data'
import AdvisoryActivitiesLanding from './component'
import { LANG_CONTENT_TYPE } from './constants'

function getLanguageToggle(content) {
  // Gets language toggle for groupedContent
  if (Array.isArray(content?.groupedContent?.content)) {
    return content.groupedContent.content.find(
      item =>
        item?.contentType &&
        item.contentType.toLowerCase() === LANG_CONTENT_TYPE,
    )
  }
}

export const AdvisoryActivitiesLandingContainer = props => {
  const {
    location,
    navigationEvent,
    routeContent,
    getEntryWithNewLocale,
    getEntryWithNewLocaleCurrentNode,
    descriptionImage,
    descriptionPdf,
    locale,
  } = props || {}

  const { content = {}, route } = routeContent
  const { entryId } = route || {}
  const { groupedContent, activityList } = content || {}
  const { parentContent } = groupedContent || {}
  const languageToggle = getLanguageToggle(content)

  const { displayTitle: title, description } = parentContent || {}
  const listProps = getListProps(parentContent)
  const videoProps = getVideoData(parentContent)
  const contentOverview = {
    title,
    description,
    listProps,
    videoProps,
  }
  const { pathname } = location || {}
  const contentCardList = formContentCardList(groupedContent, pathname)

  const handleContentChange = contentType => {
    const { route } = contentType
    navigationEvent(route)
  }

  const handleGetEntryWithNewLocale = locale => {
    if (getEntryWithNewLocaleCurrentNode) {
      getEntryWithNewLocaleCurrentNode(locale)
    }
    if (getEntryWithNewLocale) {
      getEntryWithNewLocale(locale)
    }
  }

  useEffect(() => {
    rerouteToFirstTab()
  }, [content])

  const rerouteToFirstTab = () => {
    const { listContent } = activityList || {}
    const { tabSummaries } = listContent?.[0] || {} // route to the first tab
    const { route } = tabSummaries?.[0] || {}

    if (!!activityList && !!route) {
      navigationEvent(route, NavigationHelper.types.REPLACE)
    }
  }

  return (
    contentCardList && (
      <AdvisoryActivitiesLanding
        contentCardList={contentCardList}
        contentOverview={contentOverview}
        descriptionImage={descriptionImage}
        descriptionPdf={descriptionPdf}
        entryId={entryId}
        getEntryWithNewLocale={handleGetEntryWithNewLocale}
        handleContentChange={handleContentChange}
        languageToggle={languageToggle}
        locale={locale}
        location={location}
        navigationEvent={navigationEvent}
      />
    )
  )
}

const options = {
  app: ({ route }) =>
    route.includes('family-resources')
      ? RouteContentProductAppMap.learnOrphans
      : resolveRouteServiceApp(route),
  routeResolver: () => window?.location?.pathname,
}

export default RouteContentGetter(
  withApiError(AdvisoryActivitiesLandingContainer),
  options,
)
