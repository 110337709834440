import styled from 'styled-components'
import { themeGet } from 'styled-system'

const StartLink = styled.a`
  background: ${themeGet('colors.buttons.startBackground')};
  border: 2px solid ${themeGet('colors.buttons.startBackground')};
  color: ${themeGet('colors.buttons.primaryFont')};
  padding: 0.5625rem 0 0.375rem;
  border-radius: ${themeGet('buttons.borderRadius')};
  min-width: 106px;
  font-family: ${themeGet('fontFamilySemibold')};
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  text-decoration: none;

  h5 {
    color: ${themeGet('colors.buttons.primaryFont')};
  }

  :disabled {
    background: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
    border-color: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
  }

  &:not([disabled]):hover {
    background: ${themeGet('colors.buttons.startBackgroundHover')};
    border-color: ${themeGet('colors.buttons.startBackgroundHover')};
  }
`

export default StartLink
