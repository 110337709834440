import styled from 'styled-components'
import { HeaderThree } from 'secondstep-components'
import { themeGet } from 'styled-system'

const StaticHeaderThree = styled(HeaderThree)`
  margin-top: 0.75rem;
  margin-bottom: 0.188rem;
  font-family: ${themeGet(
    'fonts.header.three.family',
    themeGet('fontFamilyBold'),
  )};
  font-weight: ${themeGet('fonts.header.three.weight', 'unset')};
  font-size: 1.25rem;
`

export default StaticHeaderThree
