import React, { createRef, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toUrlSafe } from 'utils/stringHelpers'
import { getLmsPath, getFtueViewField } from './helper'
import SelaProductContent from 'components/dashboard/SelaProductContent'
import ProductClasses from 'components/dashboard/ProductClasses'
import FamilyResourceLinks from 'components/dashboard/FamilyResourceLinks'
import OstProductLinks from 'components/dashboard/OstProductLinks'
import NonProductLinks from 'components/dashboard/NonProductLinks'
import HighSchoolProgramCard from 'components/dashboard/HighSchoolProgramCard'
import PAKActivationCard from 'components/dashboard/PAKActivationCard'
import ProgramCard from 'components/dashboard/ProgramCard'
import {
  ProgramCardsContainer,
  StyledPlainProgramCard,
} from './component.styles'
import { SUBHEADER_KIT, SUBHEADER_TEACH } from './constants'

const ActiveTabProgramCards = props => {
  const {
    activeTabProgramsAvailable,
    classes,
    contentfulCourses,
    contentfulModules,
    currentProgramName,
    firstName,
    getActiveTraining,
    getAvailableLinks,
    getProductType,
    lmsFTPath,
    lmsSelaPath,
    lmsTrainings,
    onComponentClick,
  } = props
  const [focusedClassIndex, setFocusedClassIndex] = useState(null)
  const [isHsInstancedView, setIsHsInstancedView] = useState(false)
  const classMenuButtonRefs = useRef([])
  const prevClassLength = useRef()
  const emptyClassesAddButtonRef = useRef(null)
  const addClassButtonAfterFTUERef = useRef(null)

  if (classMenuButtonRefs.current.length !== classes.length) {
    classMenuButtonRefs.current = Array(classes.length)
      .fill()
      .map((_, i) => classMenuButtonRefs.current[i] || createRef())
  }

  const hasEmptyClasses = prevClassLength.current === 1 && classes.length === 0
  useEffect(() => {
    if (
      prevClassLength.current === null ||
      (focusedClassIndex === null && hasEmptyClasses)
    ) {
      prevClassLength.current = classes.length
      return
    }

    const hasEdited = prevClassLength.current === classes.length
    const hasDeleted = prevClassLength.current > classes.length

    switch (true) {
      case hasEdited:
        setFocus(classMenuButtonRefs.current[focusedClassIndex])
        break
      case hasDeleted:
        if (classes.length > 0) {
          const newFocusClassIndex = Math.max(0, focusedClassIndex - 1)
          setFocusedClassIndex(newFocusClassIndex) // async - wont get updated in useEffect
          setFocus(classMenuButtonRefs.current[newFocusClassIndex])
        } else {
          setFocusedClassIndex(null)
          setFocus(emptyClassesAddButtonRef)
        }
        break
    }

    prevClassLength.current = classes.length
  }, [classes])

  const setFocus = element => {
    if (element) {
      element.current?.focus()
    }
  }

  const onClassFocus = classIndex => {
    setFocusedClassIndex(classIndex)
  }
  const onClassBlur = () => {
    setFocusedClassIndex(null)
  }

  return (
    <ProgramCardsContainer
      dataTestId="dashboard-program-container-exists"
      key="dashboard-program-exists"
    >
      {activeTabProgramsAvailable &&
        activeTabProgramsAvailable.map((program, index) => {
          const { displayTitle, internalTitle, displayTitleLink } = program

          const {
            isFamilyProduct,
            isHighSchool,
            isHighSchoolFamily,
            isK5Product,
            isMindYetiProduct,
            isMsProduct,
            isOstProduct,
            isSelaProduct,
          } = getProductType(internalTitle)

          const lmsPath =
            isSelaProduct && getLmsPath(internalTitle, lmsSelaPath, lmsFTPath)

          const {
            staticLinksAvailable,
            trainingLinksAvailable,
            sortedTeachLinks,
          } = getAvailableLinks(program)

          if (
            !isHighSchool &&
            !isK5Product &&
            !isMsProduct &&
            !isSelaProduct &&
            (staticLinksAvailable || []).length === 0 &&
            (trainingLinksAvailable || []).length === 0 &&
            (sortedTeachLinks || []).length === 0
          )
            return null

          const staticLinksAvailableExist = staticLinksAvailable?.length > 0
          const lmsPathExist = lmsPath?.length > 0
          const headerDataTestId = `subheader-${toUrlSafe(displayTitle)}`
          const firstTimeMS = classes.length === 0
          const programSubheader =
            isK5Product || isMsProduct ? SUBHEADER_TEACH : SUBHEADER_KIT

          return (
            <ProgramCard
              contentfulCourses={contentfulCourses}
              displayTitle={displayTitle}
              displayTitleLink={displayTitleLink}
              firstName={firstName}
              firstTime={firstTimeMS}
              isHighSchool={isHighSchool}
              isHighSchoolFamily={isHighSchoolFamily}
              isHsInstancedView={isHsInstancedView}
              isK5Product={isK5Product}
              isMindYetiProduct={isMindYetiProduct}
              isMsProduct={isMsProduct}
              isOstProduct={isOstProduct}
              isSelaProduct={isSelaProduct}
              key={`${displayTitle}-${index}`}
              onComponentClick={onComponentClick}
              program={internalTitle}
              ref={addClassButtonAfterFTUERef}
            >
              {isSelaProduct && (
                <SelaProductContent
                  contentfulModules={contentfulModules}
                  internalTitle={internalTitle}
                  lmsPath={lmsPath}
                  lmsPathExist={lmsPathExist}
                  onComponentClick={onComponentClick}
                  staticLinksAvailable={staticLinksAvailable}
                  staticLinksAvailableExist={staticLinksAvailableExist}
                />
              )}
              <ProductClasses
                classMenuButtonRefs={classMenuButtonRefs}
                classes={classes}
                contentfulCourses={contentfulCourses}
                currentProgramName={currentProgramName}
                displayTitle={displayTitle}
                entryId={getFtueViewField(program)?.id}
                firstTimeMS={firstTimeMS}
                headerDataTestId={headerDataTestId}
                index={index}
                internalTitle={internalTitle}
                isK5Product={isK5Product}
                isMsProduct={isMsProduct}
                onClassBlur={onClassBlur}
                onClassFocus={onClassFocus}
                onComponentClick={onComponentClick}
                program={program}
                programSubheader={programSubheader}
                setFocus={setFocus}
                staticLinksAvailable={staticLinksAvailable}
              />
              {isFamilyProduct && (
                <FamilyResourceLinks
                  onComponentClick={onComponentClick}
                  staticLinksAvailable={staticLinksAvailable}
                />
              )}
              {isHighSchool && sortedTeachLinks?.length > 0 && (
                <HighSchoolProgramCard
                  displayTitle={displayTitle}
                  headerDataTestId={headerDataTestId}
                  setIsHsInstancedView={setIsHsInstancedView}
                  sortedTeachLinks={sortedTeachLinks}
                  staticLinksAvailable={staticLinksAvailable}
                />
              )}
              {isOstProduct && (
                <OstProductLinks
                  displayTitle={displayTitle}
                  headerDataTestId={headerDataTestId}
                  sortedTeachLinks={sortedTeachLinks}
                  staticLinksAvailable={staticLinksAvailable}
                />
              )}
              <NonProductLinks
                displayTitle={displayTitle}
                getActiveTraining={getActiveTraining}
                headerDataTestId={headerDataTestId}
                isFamilyProduct={isFamilyProduct}
                isHighSchool={isHighSchool}
                isK5Product={isK5Product}
                isMindYetiProduct={isMindYetiProduct}
                isMsProduct={isMsProduct}
                isOstProduct={isOstProduct}
                isSelaProduct={isSelaProduct}
                lmsTrainings={lmsTrainings}
                onComponentClick={onComponentClick}
                programSubheader={programSubheader}
                sortedTeachLinks={sortedTeachLinks}
                staticLinksAvailable={staticLinksAvailable}
                trainingLinksAvailable={trainingLinksAvailable}
              />
            </ProgramCard>
          )
        })}
      <StyledPlainProgramCard dataTestId="program-card-mobile-pak-container">
        <PAKActivationCard dataTestId="pak-activation-mobile" />
      </StyledPlainProgramCard>
    </ProgramCardsContainer>
  )
}

ActiveTabProgramCards.propTypes = {
  activeTabProgramsAvailable: PropTypes.array,
  classes: PropTypes.array,
  contentfulCourses: PropTypes.array,
  contentfulModules: PropTypes.array,
  currentProgramName: PropTypes.string,
  firstName: PropTypes.string,
  getActiveTraining: PropTypes.func,
  getAvailableLinks: PropTypes.func,
  getProductType: PropTypes.func,
  lmsFTPath: PropTypes.string,
  lmsSelaPath: PropTypes.array,
  lmsTrainings: PropTypes.array,
  onComponentClick: PropTypes.func,
}

export default ActiveTabProgramCards
