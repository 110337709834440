import variables from './variables'
import baseTheme from './middleSchool'
import platform from './platform'
import whiteHills from '../static/img/white-hills.svg'
import playButton from '../static/img/play.svg'
import blueHillsGradient from '../static/img/blue-hills-gradient.svg'

export default {
  ...baseTheme,
  meta: { themeName: 'highschool' },
  font: {
    ...baseTheme.font,
    ...platform.font,
  },
  colors: {
    ...baseTheme.colors,
    modalOverlay: '#E5E9D9',
    accordion: {
      headerTextColor: variables.colors.highschool.green2,
      icon: variables.colors.highschool.pink1,
      iconHoverColor: variables.colors.highschool.green1,
      panelColor: variables?.colors?.highschool?.green0,
    },
    backgroundNeutralGray: variables.colors.highschool.green0,
    links: {
      primary: variables.colors.highschool.pink1,
      primaryHover: variables.colors.highschool.green1,
    },
    gradients: {
      ...baseTheme.colors.gradients,
      educators: `radial-gradient(ellipse at left top, ${variables.colors.highschool.beige1}, ${variables.colors.highschool.pink3})`,
      students: `radial-gradient(ellipse at left top, ${variables.colors.highschool.beige2}, ${variables.colors.highschool.green4})`,
      leaders: `radial-gradient(ellipse at left top, ${variables.colors.highschool.beige1}, ${variables.colors.highschool.cyan1})`,
    },
    themePrimary: variables.colors.highschool.pink1,
    themeSecondary: variables.colors.highschool.green1,
    themeAccent: variables.colors.highschool.green2,
    themeHighlight: variables.colors.highschool.pink2,
    themeDark: variables.colors.highschool.pink3,
    mutedBackground: variables.colors.highschool.green0,
    classCardHover: variables.colors.middleSchoolMediumBlue,
    markerOverride: variables.colors.highschool.pink1,
    backgrounds: {
      ...baseTheme.colors.backgrounds,
      primary: variables.colors.highschool.green1,
      educators: variables.colors.highschool.pink2,
      students: variables.colors.highschool.green3,
      leaders: variables.colors.highschool.cyan1,
    },
    headers: {
      ...baseTheme.colors.headers,
      primaryHeader: variables.colors.highschool.green2,
    },
    headerBase: variables.colors.navyBlue,
    heading: {
      ...baseTheme.colors.heading,
      level: {
        ...baseTheme.colors.heading.level,
        1: variables.colors.highschool.green2,
        2: variables.colors.highschool.green2,
        3: variables.colors.highschool.green2,
        4: variables.colors.highschool.green2,
        5: variables.colors.highschool.green2,
        6: variables.colors.highschool.green2,
      },
    },
    borders: {
      ...baseTheme.colors.borders,
      primary: variables.colors.highschool.green3,
    },
    buttons: {
      ...baseTheme.colors.buttons,
      primaryBackground: variables.colors.highschool.pink1,
      primaryBackgroundHover: variables.colors.highschool.green1,
      primary: {
        ...baseTheme.colors.buttons.primary,
        bgActive: variables.colors.highschool.pink1,
        bgHover: variables.colors.highschool.green1,
        titleActive: variables.colors.white,
        titleHover: variables.colors.white,
      },
      secondary: {
        ...baseTheme.colors.buttons.secondary,
        bgActive: variables.colors.highschool.green0,
        bgHover: variables.colors.highschool.green0,
        labelActive: variables.colors.highschool.green5,
        labelHover: variables.colors.highschool.pink1,
        titleActive: variables.colors.highschool.green2,
        titleHover: variables.colors.highschool.green2,
      },
      outlined: {
        ...baseTheme.colors.buttons.outlined,
        labelHover: variables.colors.highschool.pink1,
        label: variables.colors.highschool.green1,
      },
      disabled: {
        bgDisabled: variables.colors.highschool.gray1,
        titleDisabled: variables.colors.highschool.green2,
      },
    },
    modals: {
      overlay: variables.colors.highschool.green7,
    },
  },
  dashboard: {
    border: {
      width: '2px',
      color: variables.colors.paleGray,
    },
  },
  videoPlayButton: {
    // We want to simulate the AMP button as much as possible here
    ...baseTheme.videoPlayButton,
    throb: true,
    background: 'transparent',
    backgroundHover: 'transparent',
    image: playButton,
    boxShadow: '0px 0px 4px black',
  },
  heading: {
    ...baseTheme.heading,
    level: {
      ...baseTheme.heading.level,
      '1': {
        font: {
          family: variables.font.komet,
          weight: 800,
        },
        medium: {
          height: '2.875rem',
          size: '2.625rem',
        },
        spacing: '1.11px',
      },
      '2': {
        font: {
          family: variables.font.komet,
          weight: 800,
        },
        medium: {
          height: '2.125rem',
          size: '1.75rem',
        },
        spacing: '1.11px',
      },
      '3': {
        font: {
          family: variables.font.komet,
          weight: 700,
        },
        spacing: '1.11px',
      },
      '4': {
        font: {
          family: variables.font.komet,
          weight: 700,
        },
        medium: {
          height: '1.3125rem',
          size: '1.0625rem',
        },
        spacing: '1.11px',
      },
    },
  },
  banners: {
    ...baseTheme.banners,
    headerTextColor: variables.colors.green2,
    headerTextFont: variables.font.komet,
    headerTextFontWeight: 800,
    headerMargin: '1rem',
    backgroundImage: undefined,
    bannerTextColor: variables.colors.green2,
    landingBackgroundColor: variables.colors.highschool.green5,
    landingBackgroundImageAlignment: 'bottom',
    landingHeight: '18.75rem',
    tabletSublandingBackgroundSize: '',
    tabletSublandingBackgroundPosition: '',
    mobileSublandingBackgroundPosition: '',
    alignment: 'center',
    textAlignment: 'center',
    contentTypeDescriptionBackgroundColor: 'transparent',
    contentTypeDescriptionBackgroundImage: undefined,
    contentTypeDescriptionIconColor: variables.colors.white,
    landingBackgroundImage: blueHillsGradient,
    sublandingBackgroundImage: whiteHills,
    tabletSublandingBackgroundImg: whiteHills,
    mobileSublandingBackgroundImg: whiteHills,
    trainingPage: {
      headerTextColor: variables.colors.highschool.green2,
      bannerTextColor: variables.colors.highschool.green2,
      landingBackgroundImage: blueHillsGradient,
      sublandingBackgroundImage: blueHillsGradient,
      tabletSublandingBackgroundImg: blueHillsGradient,
      mobileSublandingBackgroundImg: blueHillsGradient,
    },
    supportPage: {
      headerTextColor: variables.colors.highschool.green2,
      bannerTextColor: variables.colors.highschool.green2,
      landingBackgroundImage: blueHillsGradient,
      sublandingBackgroundImage: blueHillsGradient,
      tabletSublandingBackgroundImg: blueHillsGradient,
      mobileSublandingBackgroundImg: blueHillsGradient,
    },
  },
  anchorNavigation: {
    ...baseTheme.anchorNavigation,
    borderColor: variables.colors.highschool.green3,
    boxShadow: 'none',
  },
  anchoredPage: {
    backgroundColor: variables.colors.highschool.green0,
  },
  callouts: {
    ...baseTheme.callouts,
    headerTextColor: variables.colors.highschool.green2,
    headerTextFont: variables.font.komet,
    headerTextFontWeight: 800,
    headerTextSize: '28px',
    bodyTextColor: variables.colors.darkGray,
    backgroundColor: variables.colors.white,
    categoryBodyCopyColor: variables.colors.darkGray,
    iconBorderRadius: '0.688rem 0.688rem 2.813rem 0.688rem',
    boxShadow: `none`,
    border: `1.5px solid ${variables.colors.highschool.green3}`,
    categoryBackground: variables.colors.highschool.green5,
    categoryTileBackground: variables.colors.highschool.blue,
    categoryBackgroundHover: variables.colors.highschool.lightBlue,
    categoryBorder: 'none',
    categoryTileH3Color: variables.colors.highschool.green2,
    svgFillColor: variables.colors.highschool.blue,
  },
  fonts: {
    ...baseTheme.fonts,
    primary: variables.font.komet,
    header: {
      three: {
        family: 'komet,"Helvetica","Arial",sans-serif',
        weight: 700,
      },
    },
    kometBold17: {
      fontFamily: variables.font.komet,
      fontSize: '1.0625rem',
      lineHeight: '1.125rem',
      weight: 700,
    },
    kometBold20: {
      fontFamily: variables.font.komet,
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      weight: 700,
    },
    kometHeavy28: {
      fontFamily: variables.font.komet,
      fontSize: '1.75rem',
      lineHeight: '1.75rem',
      weight: 800,
    },
    kometHeavy42: {
      fontFamily: variables.font.komet,
      fontSize: '2.625rem',
      lineHeight: '2.875rem',
      weight: 800,
    },
    moldeMedium18: {
      fontFamily: variables.font.molde.medium,
      fontSize: '1.125rem',
      lineHeight: '1.25rem',
    },
    moldeMedium16: {
      fontFamily: variables.font.molde.medium,
      fontSize: '1rem',
      lineHeight: '1.063rem',
    },
    moldeRegular16: {
      fontFamily: variables.font.molde.regular,
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
    moldeRegular12: {
      fontFamily: variables.font.molde.regular,
      fontSize: '0.75rem',
      lineHeight: '0.8125rem',
    },
  },
  globalNav: {
    ...baseTheme.globalNav,
    background: variables.colors.highschool.pink1,
  },
  resources: {
    backgroundColorBottom: variables.colors.white,
  },
}
