import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, UserIcon } from './component.styles'
import { TILE_TEXT } from './constants'
import UserProgramProfileModal from 'components/dashboard/UserProgramProfileModal'

const ProgramRoleTile = ({
  currentSite,
  handleCloseModal,
  handleModalOpen,
  isModalOpen,
}) => {
  return (
    <>
      <StyledContainer onClick={handleModalOpen}>
        <UserIcon />
        {TILE_TEXT}
      </StyledContainer>

      {isModalOpen && (
        <UserProgramProfileModal
          currentSite={currentSite}
          handleClose={handleCloseModal}
        />
      )}
    </>
  )
}

ProgramRoleTile.propTypes = {
  currentSite: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func,
  handleModalOpen: PropTypes.func,
  isModalOpen: PropTypes.bool,
}

export default ProgramRoleTile
