import React from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { TOGGLE_VIEW } from 'store/userSessionManager/constants'
import AdminResourceLinkList from 'components/AdminResourceLinkList'
import AdminWelcomeGuide from 'components/AdminWelcomeGuide'
import Notifications from 'components/Notifications'
import AdminHsK8Setup from 'components/AdminHsK8Setup'
import {
  Background,
  BannerInnerWrapper,
  BannerWrapper,
  Divider,
  HeaderWrapper,
  ManagementWrapper,
  ResourceLinksWrapper,
  StyledAdminDashboardLaunchTabButton,
  StyledAdminDashboardMonitorTabButton,
  StyledProgramDropdown,
  StyledHairline,
  StyledHeaderOne,
  StyledHeaderTwo,
  StyledManageLicensesLink,
  StyledManageUsersLink,
  StyledTabSet,
} from './component.styles'
import {
  CONTENTFUL_PROGRAM_LINK_INTERNAL_TITLE,
  DASHBOARD_HEADER,
  DROPDOWN_PROGRAMS,
  PROGRAM_DROPDOWN_LABEL,
  PROGRAM_ERROR_MESSAGE,
  PROGRAM_TITLES,
} from './constants'

const AdminDashboardBanner = ({
  availableLinks,
  className,
  handleKeyPress,
  handleTabChange,
  hsToggleView,
  isLaunched,
  k8ToggleView,
  navigationEvent,
  onChangeProgram,
  onboardingSnapshot,
  programTitles,
  selectedProgram,
  unassignedLicenses,
}) => {
  const navigateToSameTab = url => {
    const type = NavigationHelper.types.OPEN
    navigationEvent(url, type)
  }

  const createProgramOptions = () => {
    return programTitles
      .map(programKey => ({
        name: DROPDOWN_PROGRAMS[programKey]?.name,
        key: programKey,
        sortId: DROPDOWN_PROGRAMS[programKey]?.sortId,
      }))
      .sort((a, b) => a.sortId - b.sortId)
  }

  const programTitle =
    PROGRAM_TITLES[selectedProgram?.name] || PROGRAM_ERROR_MESSAGE
  const isMspOrHs =
    selectedProgram?.name === 'K8' || selectedProgram?.name === 'HS'
  const isSela = selectedProgram?.name === 'SELA'
  const isBpu = selectedProgram?.name === 'BPU'
  const isCpu = selectedProgram?.name === 'CPU'
  const programOptions = createProgramOptions()
  const isMSAdminUrl = window.location.href.includes('admin/program/k8')

  if (availableLinks) {
    availableLinks = availableLinks?.filter(link => {
      return link?.internalTitle !== CONTENTFUL_PROGRAM_LINK_INTERNAL_TITLE
    })
  }

  return (
    <BannerWrapper>
      {selectedProgram && <Background program={selectedProgram?.name} />}
      <BannerInnerWrapper>
        <ManagementWrapper>
          <StyledManageUsersLink navigateOutOfAppOnClick={navigateToSameTab} />
          <Divider />
          <StyledManageLicensesLink
            navigateOutOfAppOnClick={navigateToSameTab}
          />
        </ManagementWrapper>
        <Notifications
          isMSAdminUrl={isMSAdminUrl}
          navigationEvent={navigationEvent}
          unassignedLicenses={unassignedLicenses}
        />
        <StyledHeaderTwo>{DASHBOARD_HEADER}</StyledHeaderTwo>
        <HeaderWrapper>
          {selectedProgram && (
            <>
              <StyledHeaderOne>{programTitle}</StyledHeaderOne>
              {programTitles && programTitles.length > 1 && (
                <StyledProgramDropdown
                  dataTestId="select-program"
                  defaultLabel={PROGRAM_DROPDOWN_LABEL}
                  keyProp="key"
                  labelProp="name"
                  name="Select Program"
                  onChange={onChangeProgram}
                  options={programOptions}
                  selectedOption={selectedProgram}
                  value={selectedProgram}
                />
              )}
            </>
          )}
        </HeaderWrapper>
        <StyledHairline />
        {availableLinks && (
          <ResourceLinksWrapper>
            {selectedProgram && (
              <AdminResourceLinkList
                availableLinks={availableLinks}
                navigationEvent={navigationEvent}
              />
            )}
          </ResourceLinksWrapper>
        )}
        {isMspOrHs && (
          <AdminHsK8Setup
            isLaunched={isLaunched}
            selectedProgram={selectedProgram}
          />
        )}
        {programTitle === PROGRAM_TITLES.K8 && (
          <StyledTabSet>
            <StyledAdminDashboardLaunchTabButton
              className={className}
              isActive={k8ToggleView === TOGGLE_VIEW.LAUNCH}
              label="Launch"
              onClick={() => handleTabChange(TOGGLE_VIEW.LAUNCH)}
              onKeyPress={e => handleKeyPress(e, TOGGLE_VIEW.LAUNCH)}
            />
            <StyledAdminDashboardMonitorTabButton
              className={className}
              isActive={k8ToggleView === TOGGLE_VIEW.MONITOR}
              label="Monitor"
              onClick={() => handleTabChange(TOGGLE_VIEW.MONITOR)}
              onKeyPress={e => handleKeyPress(e, TOGGLE_VIEW.MONITOR)}
            />
          </StyledTabSet>
        )}
        {programTitle === PROGRAM_TITLES.HS && (
          <StyledTabSet>
            <StyledAdminDashboardLaunchTabButton
              className={className}
              isActive={hsToggleView === TOGGLE_VIEW.LAUNCH}
              label="Launch"
              onClick={() => handleTabChange(TOGGLE_VIEW.LAUNCH)}
              onKeyPress={e => handleKeyPress(e, TOGGLE_VIEW.LAUNCH)}
            />
            <StyledAdminDashboardMonitorTabButton
              className={className}
              isActive={hsToggleView === TOGGLE_VIEW.MONITOR}
              label="Monitor"
              onClick={() => handleTabChange(TOGGLE_VIEW.MONITOR)}
              onKeyPress={e => handleKeyPress(e, TOGGLE_VIEW.MONITOR)}
            />
          </StyledTabSet>
        )}
        {(isSela || isBpu || isCpu) && (
          <AdminWelcomeGuide
            navigationEvent={navigationEvent}
            onboardingSnapshot={onboardingSnapshot}
            selectedProgram={selectedProgram}
          />
        )}
      </BannerInnerWrapper>
    </BannerWrapper>
  )
}

AdminDashboardBanner.propTypes = {
  availableLinks: PropTypes.array,
  className: PropTypes.string,
  handleKeyPress: PropTypes.func,
  handleTabChange: PropTypes.func,
  hsToggleView: PropTypes.string,
  isLaunched: PropTypes.bool,
  k8ToggleView: PropTypes.string,
  navigationEvent: PropTypes.func,
  onChangeProgram: PropTypes.func,
  onboardingSnapshot: PropTypes.object,
  programTitles: PropTypes.array,
  selectedProgram: PropTypes.object,
  unassignedLicenses: PropTypes.array,
}

export default AdminDashboardBanner
