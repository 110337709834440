import React from 'react'
import { HeaderThree } from 'secondstep-components'
import { toUrlSafe } from 'utils/stringHelpers'
import { MyKitsGradeTilesContainer, TilesContainer } from './component.styles'
import GradeTile from 'components/dashboard/GradeTile'
import ResourceAndTrainingCard from 'components/dashboard/ResourceAndTrainingCard'
import PropTypes from 'prop-types'
import { DEFAULT_LABEL } from './constants'

const NonProductLinks = ({
  isFamilyProduct,
  isHighSchool,
  isK5Product,
  isMsProduct,
  isOstProduct,
  isSelaProduct,
  headerDataTestId,
  programSubheader,
  sortedTeachLinks,
  trainingLinksAvailable,
  staticLinksAvailable,
  onComponentClick,
  getActiveTraining,
  lmsTrainings,
  isMindYetiProduct,
}) => {
  return (
    <>
      {!isFamilyProduct &&
        !isHighSchool &&
        !isK5Product &&
        !isMsProduct &&
        !isOstProduct &&
        !isSelaProduct && (
          <>
            {sortedTeachLinks?.length > 0 && (
              <>
                <HeaderThree dataTestId={headerDataTestId}>
                  {programSubheader}
                </HeaderThree>
                <MyKitsGradeTilesContainer>
                  {sortedTeachLinks.map(({ linkLabel, path }) => {
                    const checkedLinkLabel = !linkLabel
                      ? DEFAULT_LABEL
                      : linkLabel
                    return (
                      <GradeTile
                        dataTestId={`grade-tile-${toUrlSafe(checkedLinkLabel)}`}
                        displayText={checkedLinkLabel}
                        key={`grade-tile-${checkedLinkLabel}`}
                        onClick={() => onComponentClick(path)}
                        target="_self"
                      />
                    )
                  })}
                </MyKitsGradeTilesContainer>
              </>
            )}

            {(trainingLinksAvailable?.length > 0 ||
              staticLinksAvailable?.length > 0) && (
              <TilesContainer key="non-middle-school-training-and-resource-links">
                {trainingLinksAvailable?.length > 0 &&
                  trainingLinksAvailable.map((trainingLink, index) => {
                    const { attempted = 'not attempted' } = getActiveTraining(
                      trainingLink.internalTitle,
                      lmsTrainings,
                    )
                    return (
                      <ResourceAndTrainingCard
                        attempted={attempted}
                        dataTestId={`resource-and-training-card-${toUrlSafe(
                          trainingLink.displayTitle,
                        )}`}
                        headerTitle={trainingLink.displayTitle}
                        key={`resource-and-training-card-non-middle-school${index}`}
                        onClick={() => onComponentClick(trainingLink.path)}
                      />
                    )
                  })}
                {staticLinksAvailable?.length > 0 &&
                  staticLinksAvailable.map((staticLink, index) => {
                    return (
                      staticLink.displayTitle && (
                        <ResourceAndTrainingCard
                          dataTestId={`resource-and-training-card-${toUrlSafe(
                            staticLink.displayTitle,
                          )}`}
                          headerTitle={staticLink.displayTitle}
                          internalTitle={staticLink.internalTitle}
                          isMindYetiProduct={isMindYetiProduct}
                          key={`resource-and-training-card-static-${index}`}
                          linkLabel={staticLink.linkLabel}
                          onClick={() => onComponentClick(staticLink.path)}
                        />
                      )
                    )
                  })}
              </TilesContainer>
            )}
          </>
        )}
    </>
  )
}

NonProductLinks.propTypes = {
  getActiveTraining: PropTypes.func,
  headerDataTestId: PropTypes.string,
  isFamilyProduct: PropTypes.bool,
  isHighSchool: PropTypes.bool,
  isK5Product: PropTypes.bool,
  isMindYetiProduct: PropTypes.bool,
  isMsProduct: PropTypes.bool,
  isOstProduct: PropTypes.bool,
  isSelaProduct: PropTypes.bool,
  lmsTrainings: PropTypes.array,
  onComponentClick: PropTypes.func,
  programSubheader: PropTypes.string,
  sortedTeachLinks: PropTypes.array,
  staticLinksAvailable: PropTypes.array.isRequired,
  trainingLinksAvailable: PropTypes.array,
}

export default NonProductLinks
