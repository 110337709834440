import React from 'react'
import PropTypes from 'prop-types'
import { toTitleCase } from 'utils/stringHelpers'
import CoursePreview from './component'
import {
  RouteContentGetter,
  RouteContentType,
} from 'layers/content/v2/Hocs/RouteContentGetter'
import withApiError from 'layers/errorHandling/apiError'
import { UnitType } from 'components/UnitComponent/types'
import { resolveRouteServiceApp } from 'utils/productHelpers'

// TODO: This is problematic from an i18n perspective
export function getPageTitleFromSlug(slug) {
  if (slug === 'grade-k') {
    return 'Kindergarten Lessons'
  }
  return toTitleCase(slug.replace(/-/g, ' ') + ' Lessons').trim()
}

export function countLessonsInUnits(units) {
  return units?.reduce((acc, unit) => acc + unit?.lessons?.length, 0) ?? 0
}

export function unitIsOutOfRange(unitIndex, units) {
  return unitIndex < 0 || unitIndex >= units.length
}

export const Container = props => {
  const {
    routeContent,
    match,
    onSelectUnit,
    productName,
    unitId,
    getData,
  } = props
  const { content, loading } = routeContent
  const title = getPageTitleFromSlug(match?.params?.course ?? '')

  const { unit } = content
  const { acknowledgements, scopeAndSequenceData, lessonsAmounts } = getData(
    unit,
  )
  return (
    !loading && (
      <CoursePreview
        acknowledgements={acknowledgements}
        lessonsAmounts={lessonsAmounts}
        match={match}
        onSelectUnit={onSelectUnit}
        productName={productName}
        scopeAndSequenceData={scopeAndSequenceData}
        title={title}
        unit={unit}
        unitId={unitId}
      />
    )
  )
}

Container.propTypes = {
  getData: PropTypes.func.isRequired,
  match: PropTypes.object,
  onSelectUnit: PropTypes.func.isRequired,
  productName: PropTypes.string,
  routeContent: RouteContentType(UnitType).isRequired,
  unitId: PropTypes.string.isRequired,
}
const CoursePreviewContainer = RouteContentGetter(withApiError(Container), {
  app: ({ route }) => resolveRouteServiceApp(route),
})

CoursePreviewContainer.displayName = 'CoursePreviewContainer'

export default CoursePreviewContainer
