let breakpointSizes = {
  large: '1366px',
  largish: '1235px',
  medium: '992px',
  tablet: '768px',
  tabletLarge: '800px',
  belowTablet: '750px',
  mobileLarge: '580px',
  mobile: '375px',
}

let variables = {
  breakpoints: {
    xlarge: `@media screen and (min-width: ${breakpointSizes.large})`,
    large: `@media screen and (max-width: ${breakpointSizes.large})`,
    largish: `@media screen and (max-width: ${breakpointSizes.large})`,
    medium: `@media screen and (max-width: ${breakpointSizes.medium})`,
    tablet: `@media screen and  (max-width: ${breakpointSizes.tablet})`,
    tabletLarge: `@media screen and  (max-width: ${breakpointSizes.tabletLarge})`,
    belowTablet: `@media screen and (max-width: ${breakpointSizes.belowTablet})`,
    mobile: `@media screen and (max-width: ${breakpointSizes.mobile})`,
    mobileLarge: `@media screen and (max-width: ${breakpointSizes.mobileLarge})`,
    sizes: breakpointSizes,
    admin: {
      large: `@media screen and (max-width: 1115px)`,
    },
  },
  borderRadius: '0.25rem',
  borderRadiusLg: '0.5rem',

  buttons: {
    borderRadiusBase: '6.25rem',
    borderRadiusSquare: '0.15rem',
    borderRadiusRound: '2rem',
    widthBase: '6.87rem',
    borderBase: '1px solid #495057',
    buttonBorder: '3px solid rgba(255, 255, 255, 0.6)',
    fontSizeBase: '0.812rem',
    fontWeightBase: '600',
    startBtBorder: '1px solid #3399FF',

    paddingSize: {
      small: '0.5rem 2.5rem',
      standard: '0.75rem 1.75rem',
      large: '1rem 2.5rem',
    },
  },

  containerMaxWidth: '1366px',

  fontFamily: 'Molde-regular',
  fontFamilySemibold: 'Molde-medium',
  fontFamilyBold: 'Molde-Semibold',

  // keep one or the other header font variable, decided when theming is finalized
  fontFamilyHeadingBold: 'SharpSlab-Bold',
  fontFamilyHeadingExtraBold: 'SharpSlab-Extrabold',
  fontFamilyHeadingMedium: 'SharpSlab-Medium',

  fontWeightNormal: 400,

  font: {
    lufgaCFC: {
      regular: 'LufgaCFC-Regular',
      medium: 'LufgaCFC-Medium',
      bold: 'LufgaCFC-Bold',
      semiBold: 'LufgaCFC-SemiBold',
    },
    molde: {
      regular: 'Molde-regular',
      medium: 'Molde-medium',
      semiBold: 'Molde-Semibold',
    },
    sharpSlab: {
      medium: 'SharpSlab-medium',
      semibold: 'SharpSlab-Semibold',
      bold: 'SharpSlab-Bold',
      extraBold: 'SharpSlab-Extrabold',
    },
    museoSansRounded: {
      regular: 'MuseoSansRounded-Regular',
      semibold: 'MuseoSansRounded-Semibold',
      bold: 'MuseoSansRounded-Bold',
    },
    komet: 'komet, "Helvetica", "Arial", sans-serif',
    size: {
      text: {
        base: '1rem',
      },
      buttons: {
        base: '1rem',
      },
      headers: {
        h1: '2.25rem',
        h1Large: '3.5rem',
        h2: '1.75rem',
        h2Large: '2.5rem',
        h3: '1.5rem',
        h4: '1.3125rem',
        h5: '1.25rem',
        h6: '1.125rem',
      },
    },
  },

  colors: {
    // palette
    backgroundBlueLight: '#EFF9FF',
    beaconGray: '#ced8df',
    black: '#000',
    blossom: '#eab4cb',
    blue: '#3c6e90',
    blueGray: '#3C6E90',
    blueGrayMedium: '#8393af',
    blueLight: '#f0f1f6',
    blueOpaque: 'rgba(60, 110, 144, 0.75)',
    contentBackgroundGray: '#F0F0F0',
    darkBlue: '#002855',
    darkerDarkBlue: '#25282A',
    darkishGray: '#9F9F9F',
    darkGray: '#555555',
    darkerGray: '#444444',
    disabledGray: '#aaaaaa',
    earlyLearningBlue: '#148BBF',
    earlyLearningDarkerBlue: '#10688F',
    gray100: '#F3F3F3',
    gray200: '#e9ecef',
    gray300: '#dee2e6',
    gray400: '#cccccc',
    gray500: '#DDDDDD',
    gray550: '#EDF0F2',
    gray600: '#999999',
    gray625: '#979797',
    gray650: '#909090',
    gray675: '#545454',
    gray700: '#495057',
    gray800: '#343a40',
    gray900: '#212529',
    gray1000: '#D9DFE6',
    gray1100: '#757575',
    gray1200: '#949494',
    iconBlue: '#0f3964',
    lightBlue: '#4B63DD',
    lighterBlue: '#C4D3DE',
    lightishBlueGray: '#B8C8D4',
    lightBlueGray: '#EEF6FA',
    lighterGray: '#f0f1f6',
    lightGray: '#888888',
    lightGrayishBlue: '#e0e6eb',
    lightShadeGray: '#a3a3a3',
    lightSlateGray: '#8094ab',
    mediumGray: '#666666',
    mediumDarkGray: '#e3e5eb',
    middleSchoolBlue: '#386a8b',
    middleSchoolDarkBlue: '#0a3661',
    middleSchoolMediumBlue: '#193D66',
    hsPrimaryPink: '#C62D70',
    hsPrimaryPinkLight: 'rgba(198,45,112,0.5)',
    hsSecondaryLightGreen: '#F1F6EA',
    hsSecondaryLightGreenSemiTransparent: 'rgba(241,246,234,0.5)',
    hsSecondaryGreen: '#677F1A',
    hsSecondaryDarkGreen: '#023125',
    navyBlue: '#212b36',
    paleGray: '#eef0f2',
    secondStepBlue: '#002855',
    transparent: 'transparent',
    white: '#fff',
    zircon: '#E0EBEB',

    focusBackground: '#eff6fb',

    orangePale: '#FF8A79',
    lightOrange: '#E77564',
    principalToolkitOrange: '#e17240',
    orange: '#E1523D',
    secondStepOrange: '#E1523D',
    orange100: '#e97e6e',

    tomatoRed: '#cf3b32',
    red: '#ff0000',
    deleteRed: '#B8002F',
    mediumRed: '#D41B00',
    darkRed: '#a21400',
    errorRed: '#ce1443',

    yellow: '#ffbf3f',
    darkYellow: '#cd9933',

    lightGreen: '#BFDADA',
    checkmarkGreen: '#4ed474',
    elementaryGreen: '#226f6b',
    extendGreen: '#01b7ab',
    tealKit: '#4B908C',
    teal: '#487a7b',
    tealKitHover: '#165A57',
    mediumTeal: '#00847F',
    darkTeal: '#2b494a',
    tealButtonIconInset: '#247470',
    tealButton: '#579491',
    tealButtonHover: '#2F6967',

    pink: '#ff7999',

    professionalDevelopmentPurple: '#805acd',
    purple: '#624d89',
    darkPurple: '#41325c',
    okGreen: '#33a86a',
    warningRed: '#dc3545',
    violet: '#5C5FB9',

    goldenYellow: '#FFAB00',
    coralPink: '#EC3450',
    emeraldGreen: '#00BC82',
    grayBlue: '#CED8DF',

    // TODO: fake colors, can be removed once real theme colors are given from design
    skittlesPink: '#F2059F',
    skittlesBlue: '#05DBF2',
    skittlesGreen: '#04D94F',
    skittlesYellow: '#F2CB05',
    skittlesOrange: '#F28705',

    // links
    linkBase: '#42a5f5',
    linkVisited: '#805aa6',
    grayLink: '#666',
    linkLight: '#ffffff',
    linkLightHover: '#ff8a79',

    // backgrounds
    backgroundBody: '#1f1f1f',
    backgroundButtonLight: 'rgba(255, 255, 255, 0.28)',
    backgroundModalLight: 'rgba(255, 255, 255, 0.8)',
    backgroundModalLightNoOpacity: 'rgba(255, 255, 255)',
    backgroundModalDark: 'rgba(69,79,91,0.88)',
    backgroundNeutralGray: '#f1f1f1',
    backgroundLightGray: '#f2f2f2',
    backgroundNeutralGrayOneAccent: '#999',
    backgroundNeutralGrayTwoAccent: '#888',
    backgroundSuccess: '#ebf8dd',
    backgroundInfo: '#e3ebf0',
    backgroundWarning: '#fdf6da',
    backgroundDanger: '#f6cad0',
    backgroundDarkBlueGradient: 'linear-gradient(90deg, #002855 ,#0C958F)',
    backgroundChineseWhite: '#dce6e5',

    // fonts
    fontLight: '#fff',
    textTwo: '#454f5b',
    gradeLogo: '#42a5f5',
    gradeLogoHover: '#4A8CE2',

    // borders
    borderBase: '#495057',

    // components
    progressFill: '#eec200',
    progressBackground: '#eff1f3',
    progressInactiveBackground: '#fff',
    classTile: '#212b36',

    tabTitleActive: '#4f90b2',
    tabTitleInactive: '#212b36',
    tabBorder: '#c4cdd5',
    tabUnselect: '#d7d7d7',

    buttonTextBase: '#3e4e57',
    buttonStart: '#3399ff',
    buttonRestart: '#00cc99',
    buttonDisabled: '#112748',
    buttonDisabledKit: '#2f495c',
    buttonGrayDisabled: '#6E767C',

    termsModalWhiteBlue: '#f1f3f5',
    termsModalBorder: '#B5BFC9',

    // forms
    formInputBorder: '2px solid #ced8df',
    inputPlaceholder: 'rgba(0,0,0,.59)',

    // boxShadow
    boxShadow: 'rgba(182,182,182,0.5)',

    opacity_20: 'rgba(0,0,0,0.2)',
    opacity_40: 'rgba(0,0,0,0.4)',

    // https://projects.invisionapp.com/d/main/#/console/20385854/427802951/preview
    // Colors range from darkest to lightest, where 1 is the darkest.
    // Elementary Classroom Kits - Teal
    k5kit1: '#02413E',
    k5kit2: '#03615D',
    k5kit3: '#0F6F6B',
    k5kit4: '#04817C',
    k5kit5: '#369A96',
    k5kit6: '#81C0BD',
    k5kit7: '#C0DFDE',

    // Early Learning Kit - Blue
    elkit1: '#23396C',
    elkit2: '#3556A2',
    elkit3: '#4264B2',
    elkit4: '#4672D8',
    elkit5: '#6B8EE0',
    elkit6: '#A2B8EB',
    elkit7: '#D1DCF5',

    // Bullying Prevention Unit - Purple
    bpu1: '#2F2756',
    bpu2: '#473A80',
    bpu3: '#54488F',
    bpu4: '#5E4DAB',
    bpu5: '#7E71BC',
    bpu6: '#AEA6D5',
    bpu7: '#D7D2EA',

    // Child Protection Unit - Violet
    cpu1: '#4E2351',
    cpu2: '#74357A',
    cpu3: '#83408A',
    cpu4: '#9B46A2',
    cpu5: '#AF6BB5',
    cpu6: '#CDA2D0',
    cpu7: '#E6D1E8',
    cpu8: '#222f66',

    // Middle School 2008 - Navy
    mskit1: '#1E3748',
    mskit2: '#2D536C',
    mskit3: '#37607D',
    mskit4: '#3C6E90',
    mskit4Opaque: 'rgba(60, 110, 144, 0.2)', // background of Class Card ellipsis hover
    mskit5: '#638BA6',
    mskit6: '#9DB6C7',
    mskit7: '#CEDBE3',

    // Middle School Digital
    msdigital2: '#002855',
    msdigital4: '#00142B',

    // Out of School Time - Green
    ost3: '#126064',
    ost4: '#4B8344',
    ost5: '#9E1F63',
    ost6: '#D8D8D8',
    ost7: '#002855',

    // Elementary Digital
    elemDigital1: '#04817C', // light teal - highlight/accent
    elemDigital2: '#03615D', // teal - theme primary
    elemDigital3: '#004543', // dark teal - theme secondary
    elemDigital4: '#73419F', // purple
    elemDigital4Opaque: 'rgba(115, 65, 159, 0.2)', // background of Class Card ellipsis hover
    elemDigital5: '#4E187F', // dark purple

    sela: {
      gray1: '#393939', // primary gray
      gray2: '#C3C6C8', // medium gray
      gray3: '#EEF0F2', // light gray
      gray4: '#718795', // blue-gray
      gray5: '#52585B', // medium gray - status text
      gray6: '#757575', // dark gray - microlearning title
      teal1: '#00A3A0', // primary teal
      teal2: '#B1DCDD', // medium teal
      teal3: '#E4F3F3', // light teal - resources
      teal4: '#CAE5E5', // light teal - status
      teal5: '#009996', // dark teal - for links a11y
      teal6: '#007980',
      teal7: '#001A26', // dark teal - for Admin Dashboard SELA
      purple1: '#6A5F94', // purple
      purple2: '#F1F0F5', // light purple
      purple3: '#DEDCEA', // medium purple - status box
      pink1: '#FF8286',
    },

    mindyeti: {
      blue1: '#4B63DD',
      blue2: '#313188',
      blue3: '#252570',
      blue4: '#D4EAF5',
      teal1: '#017E9D',
      teal2: '#EFF7FB',
      darkTeal: '#00455E',
      white: '#FFF',
    },

    dashboard: {
      gray1: '#757575',
    },

    highschool: {
      beige1: '#F0EFC6',
      beige2: '#F7F6BA',
      beige3: '#E5F0D8',
      blue: '#D0EFE5',
      cyan1: '#C2ECE7',
      green0: '#F1F6EA',
      green1: '#677F1A',
      green2: '#023125',
      green3: '#DAEBA9',
      green4: '#D0E78E',
      green5: '#5D7217',
      green6: '#4F6114',
      green7: '#E5E9D8',
      green8: '#ECF6DD',
      lightBlue: '#A8D9D9',
      pink1: '#C62D70',
      pink2: '#FEDDD5',
      pink3: '#FED8CF',
      pinkWithOpacity: 'rgba(198, 45, 112, 0.3)',
      pink4: '#FBC9DC',
      pink5: '#DDB6B9',
      pink6: '#FAEEF3',
      pink7: '#F9EFF2',
      gray1: '#E7E9EB',
      gray2: '#ECEEF0',
      gray3: 'rgba(246, 247, 248, 50%)',
      modalOverlay: 'rgba(229, 233, 217, 85%);',
    },
  },

  spacing: {
    // Spacing supplied by https://projects.invisionapp.com/share/BGXKOXZCU6T#/screens/420413051
    indigo70_4_375rem: '4.375rem',
    redTurquoise60_3_75rem: '3.75rem',
    orange44_2_75rem: '2.75rem',
    red40_2_5rem: '2.5rem',
    amber28_1_75rem: '1.75rem',
    purple24_1_5rem: '1.5rem',
    turquoise20_1_25rem: '1.25rem',
    blue16_1rem: '1rem',
    yellow12_0_75rem: '0.75rem',
    pink8_0_5rem: '0.5rem',
    green4_0_25rem: '0.25rem',
    size_5_75rem: '5.75rem',
  },
}

export default variables
