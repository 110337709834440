import React, { Fragment, useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Element } from 'react-scroll'
import { getProgramSnapshot } from 'utils/adminExperienceHelpers'
import {
  AccordionWrapper,
  BlockWrapper,
  Content,
  Illustration,
  StyledHairline,
  SubHeader,
  TopHeader,
  Wrapper,
} from './component.styles'
import AdminWelcomeBlock from 'components/AdminWelcomeBlock'
import { themeGet } from 'styled-system'
import { withTheme } from 'styled-components'

const AdminWelcomeGuide = ({
  isCollapsible,
  isWelcomeMessageOpen,
  navigationEvent,
  onboardingSnapshot,
  selectedProgram,
  setIsWelcomeMessageOpen,
  ...rest
}) => {
  const blockWrapperRef = useRef(null)
  const [imageHeight, setImageHeight] = useState(0)

  const updateImageSize = () => {
    const wrapperHeight = blockWrapperRef?.current?.clientHeight || 0
    setImageHeight(wrapperHeight - 10)
  }

  useEffect(() => {
    updateImageSize()
    window.addEventListener('resize', updateImageSize)

    return () => {
      window.removeEventListener('resize', updateImageSize)
    }
  }, [onboardingSnapshot])

  if (!onboardingSnapshot || !selectedProgram || imageHeight === 0) {
    // still in a loading state:
    return null
  }

  let snapshot
  try {
    snapshot = getProgramSnapshot(selectedProgram.key, onboardingSnapshot)
  } catch (err) {
    console.error(err)
    return null
  }

  const { programWelcome } = snapshot || {}
  const { heading1, heading2, welcomeSteps, image } = programWelcome || {}
  const WelcomeMessageWrapper = isCollapsible ? AccordionWrapper : Wrapper

  if (!programWelcome) {
    return null
  }

  return (
    <WelcomeMessageWrapper
      isActive={isWelcomeMessageOpen}
      onClick={() => {
        setIsWelcomeMessageOpen(!isWelcomeMessageOpen)
      }}
      panelColor={themeGet('colors.white')(rest)}
      title={<WelcomeMessageHeader heading1={heading1} heading2={heading2} />}
    >
      {!isCollapsible && (
        <WelcomeMessageHeader
          heading1={heading1}
          heading2={heading2}
          key="message-header"
        />
      )}
      <StyledHairline />
      <Content>
        {welcomeSteps && (
          <BlockWrapper key="block-key" ref={blockWrapperRef}>
            {welcomeSteps.map(({ description, link, linkTitle }, index) => (
              <Fragment key={`admin-welcome-${link?.id}`}>
                {index === 1 && <Element name="scroll-to-admin" />}
                <AdminWelcomeBlock
                  entryId={link?.id}
                  navigationEvent={navigationEvent}
                  text={description}
                  title={linkTitle}
                />
              </Fragment>
            ))}
          </BlockWrapper>
        )}
        {image && (
          <Illustration
            alt={image?.description}
            height={imageHeight}
            src={image?.file?.url}
          />
        )}
      </Content>
    </WelcomeMessageWrapper>
  )
}

const WelcomeMessageHeader = ({ heading1, heading2 }) => {
  return (
    <>
      <TopHeader>{heading1}</TopHeader>
      <SubHeader>{heading2}</SubHeader>
    </>
  )
}

WelcomeMessageHeader.propTypes = {
  heading1: PropTypes.string,
  heading2: PropTypes.string,
}

AdminWelcomeGuide.propTypes = {
  isCollapsible: PropTypes.bool,
  isWelcomeMessageOpen: PropTypes.bool,
  navigationEvent: PropTypes.func,
  onboardingSnapshot: PropTypes.array,
  selectedProgram: PropTypes.object,
  setIsWelcomeMessageOpen: PropTypes.func,
}

export default withTheme(AdminWelcomeGuide)
