import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BodyCopy,
  ContainedButton,
  Hairline,
  HeaderTwo,
  ModalContentWrapper,
} from 'secondstep-components'
import AdminExportDownloadButton from 'components/AdminExportDownloadButton'
import AdminTooltip from 'components/AdminTooltip'
import ClosableModal from 'experienceComponents/ClosableModal'

export const ChartsWrapper = styled.div`
  display: flex;
  height: 100%;

  ${themeGet('breakpoints.medium')} {
    flex-direction: column;
    align-items: center;
    padding: 0 1.25rem;

    [class*='ChartWrapper'] {
      &:not(:last-of-type) {
        margin-bottom: 3.1875rem;
      }
      &:last-of-type {
        margin-bottom: 0.625rem;
      }
    }
  }
`

export const EmptyStateMessage = styled(BodyCopy)`
  max-width: 35.625rem;
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1.125rem;
  margin: 1.5625rem 2rem 0;
  text-align: center;
`

export const EmptyStateWrapper = styled.div.attrs(({ isMultiSite }) => ({
  border: isMultiSite ? 'none' : `2px solid`,
  borderColor: isMultiSite ? 'none' : themeGet('colors.paleGray'),
  padding: isMultiSite ? '5rem 0' : '9rem 0',
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${themeGet('colors.white')};
  border: ${({ border }) => border};
  border-color: ${({ borderColor }) => borderColor};
  border-radius: 0.625rem;
  box-sizing: border-box;
  margin-bottom: 1.25rem;
  padding: ${({ padding }) => padding};
`

export const HeaderWrapper = styled.div.attrs(({ isMultiSite }) => ({
  display: isMultiSite ? 'none' : 'flex',
}))`
  display: ${({ display }) => display};
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const LastUpdatedMessage = styled(BodyCopy)`
  position: absolute;
  top: 12px;
  right: 20px;
  color: ${themeGet('colors.gray1100')};
  font-size: 0.75rem;

  @media (max-width: 759px) {
    display: none;
  }
`

export const LeftBox = styled.div``

export const MainTitle = styled(HeaderTwo)`
  font-size: 1.4375rem;
`

export const ModalBodyCopy = styled(BodyCopy)`
  margin-bottom: 2.125rem;
  color: ${themeGet('colors.darkGray')};
  line-height: 1.1875rem;
  text-align: center;
`

export const ModalCloseButton = styled(ContainedButton)`
  padding: 0.5625rem 2.9375rem 0.375rem;
  align-self: center;
`

export const ModalHeader = styled(HeaderTwo)`
  margin-bottom: 1.125rem;
  font-size: 1.4375rem;
  line-height: 1.5625rem;
  text-align: center;
`

export const MonitorSnapshotWrapper = styled.div.attrs(({ isMultiSite }) => ({
  background: isMultiSite ? 'none' : themeGet('colors.white'),
  padding: isMultiSite ? '0' : '1.375rem 1.25rem 1.25rem',
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ background }) => background};
  border-radius: 0.625rem;
  box-sizing: border-box;
  margin-bottom: 1.25rem;
  padding: ${({ padding }) => padding};
`

export const OverallProgressHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.875rem;
`

export const OverallProgressTitle = styled(HeaderTwo)`
  font-size: 1.25rem;
  margin-bottom: 0;
`

export const OverallProgressWrapper = styled.div.attrs(({ isMultiSite }) => ({
  padding: isMultiSite ? '1.375rem 1.125rem 2rem' : '1.375rem 1.125rem 2.5rem',
  paddingBottomMedium: isMultiSite ? '2.5rem' : '2',
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.625rem;
  border: 2px solid ${themeGet('colors.paleGray')};
  margin-bottom: 1.125rem;
  padding: ${({ padding }) => padding};
  box-sizing: border-box;
  background: ${themeGet('colors.white')};

  ${themeGet('breakpoints.medium')} {
    padding-bottom: ${({ paddingBottomMedium }) => paddingBottomMedium};
  }
`

export const PathwayAndProgressWrapper = styled.div``

export const RightBox = styled.div`
  display: flex;
`

export const StyledAdminExportDownloadButton = styled(
  AdminExportDownloadButton,
)`
  align-self: flex-end;
  margin-bottom: 0.3125rem;

  ::after {
    bottom: 50%;
  }
`

export const StyledAdminTooltip = styled(AdminTooltip)`
  margin: 0.25rem auto 0 0.375rem;

  [class*='TooltipContentWrapper'] {
    top: 33px;
    left: 97px;

    ::after {
      top: -46%;
      left: 27px;
    }
  }
`

export const StyledBodyCopy = styled(BodyCopy)``

export const StyledClosableModal = styled(ClosableModal)`
  ${ModalContentWrapper} {
    max-width: 650px;
    width: 100%;
    height: auto;
    padding: 2.5rem 2.5625rem;
  }
`

export const StyledDonutHairline = styled(Hairline)`
  display: none;
  height: 2px;
  margin-bottom: 2.5rem;
  background-color: ${themeGet('colors.paleGray')};

  ${themeGet('breakpoints.medium')} {
    display: block;
  }
`

export const StyledHairline = styled(Hairline)`
  height: 2px;
  margin-bottom: 2.5rem;
  background-color: ${themeGet('colors.paleGray')};
`
