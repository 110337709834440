import { FlagsComponentRouter } from 'flags'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: ['high-school-snapshot-role-count-and-layout-update'],
  OnComponent,
  OffComponent,
})

export default FlagToggler
