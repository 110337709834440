/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React from 'react'
import ResourcesSubLandingPage from './component'
import { getPageTypeByUrl } from 'utils/getPageTypeByUrl'
import { RouteContentGetter } from 'layers/content/v2/Hocs/RouteContentGetter'
import withApiError from 'layers/errorHandling/apiError'
import { resolveRouteServiceApp } from 'utils/productHelpers'
import { isPreviewMode } from 'utils/highschoolHelpers'

const ResourcesSubLandingPageContainer = props => {
  const {
    isSelaLeaderPage,
    isSelaProduct,
    location,
    navigationEvent,
    routeContent,
  } = props || {}

  const { content = {}, loading } = routeContent
  const { subsectionLanding } = content

  const readyToLoad = !!content && !loading

  const pageType = getPageTypeByUrl(location.pathname)

  const parsedEntry = mapper(subsectionLanding, location, navigationEvent) || {}

  return (
    readyToLoad && (
      <ResourcesSubLandingPage
        isHsPreview={isPreviewMode()}
        isSelaLeaderPage={isSelaLeaderPage}
        isSelaProduct={isSelaProduct}
        navigationEvent={navigationEvent}
        pageType={pageType}
        {...parsedEntry}
      />
    )
  )
}

const mapper = (entry, location, navigationEvent) => {
  const { displayTitle, description, content = [] } = entry || {}

  const articles = content.map(article => {
    const { description: body, displayTitle: title, pageData } = article || {}
    const { image } = article || {}
    const { image: image2 } = image || {}
    const { url } = image2 || {}
    const { route } = pageData || {}
    return { body, image: url, link: route, location, navigationEvent, title }
  })
  const banner = { text: description, title: displayTitle }

  entry = { articles, banner }
  return entry
}
const options = {
  app: ({ route }) => resolveRouteServiceApp(route),
  routeResolver: () => window?.location?.pathname,
}

export default RouteContentGetter(
  withApiError(ResourcesSubLandingPageContainer),
  options,
)
