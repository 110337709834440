/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import NavigationHelper from 'layers/navigation/navigationHelper'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
} from 'layers/errorHandling/apiError'
import HsResourceLibrary from './component'
import {
  HIGH_SCHOOL_INDIVIDUAL_RESOURCE_PATH,
  isPreviewMode,
  previewModeQueryParam,
} from 'utils/highschoolHelpers'
import { worker } from 'mocks/browser'
import algoliasearch from 'algoliasearch'
import searchInsights from 'search-insights'

import {
  ALGOLIA_APP_NAME,
  ALGOLIA_API_KEY,
  ALGOLIA_INDEX_NAME,
} from 'config/env'

import {
  DEFAULT_SEARCHBOX_PLACEHOLDER,
  DEFAULT_SEARCHBOX_TITLE,
} from './constants'
import { shouldUseMockServiceWorker, getAlgoliaCacheSettings } from './helpers'
import { userSegmentationBuilder } from 'utils/userSegmentationHelpers'
import {
  AI_HS_PAGE_USAGE_KEY,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelpers'

const Container = props => {
  if (shouldUseMockServiceWorker(window.location)) {
    worker.start()
  }

  const { entry, navigationEvent, userSegmentationInfo } = props || {}

  const clientOptions = {
    ...getAlgoliaCacheSettings(ALGOLIA_APP_NAME),
  }

  const searchClient = algoliasearch(
    ALGOLIA_APP_NAME,
    ALGOLIA_API_KEY,
    clientOptions,
  )
  // In the future we will give people the option to opt-in/opt-out of cookies
  searchInsights('init', {
    appId: ALGOLIA_APP_NAME,
    apiKey: ALGOLIA_API_KEY,
    partial: true,
    useCookie: true,
  })

  const searchIndex = searchClient.initIndex(ALGOLIA_INDEX_NAME)

  const [facetsFilters, setFacetsFilters] = useState([])
  const [settings, setSettings] = useState(null)
  const [isHsPreviewMode, setIsHsPreviewMode] = useState(false)

  useEffect(() => {
    let shouldGetSettings = true
    searchIndex.getSettings().then(settings => {
      if (shouldGetSettings) {
        setSettings(settings)
        const facetsFilters =
          settings?.renderingContent?.facetOrdering?.facets?.order ?? []

        const prettyFacetsFilters = facetsFilters.map(f => ({
          key: f,
          displayTitle:
            entry?.facetCardSetting.facets.find(s =>
              f
                .toUpperCase()
                .includes(s.facetId.toUpperCase().replace('METADATA', '')),
            )?.facetDisplayTitle || f,
        }))
        setFacetsFilters(prettyFacetsFilters)
        setIsHsPreviewMode(isPreviewMode())
      }
    })
    return () => (shouldGetSettings = false)
  }, [])

  useEffect(() => {
    sendApplicationInsightsEvent(AI_HS_PAGE_USAGE_KEY, userSegmentationInfo)
  }, [userSegmentationInfo])

  const onCardClick = routeSlug => {
    if (!routeSlug) return

    const searchParams = isHsPreviewMode ? previewModeQueryParam() : ''

    navigationEvent &&
      navigationEvent(
        `${HIGH_SCHOOL_INDIVIDUAL_RESOURCE_PATH}/${routeSlug}${searchParams}`,
      )
  }

  return (
    settings && (
      <HsResourceLibrary
        {...mapAdditionalProps(entry)}
        facetsFilters={facetsFilters}
        isPreview={isHsPreviewMode}
        onCardClick={onCardClick}
        searchClient={searchClient}
      />
    )
  )
}
const mapper = entry => {
  return { entry }
}
const options = {
  include: 3,
  mapper,
  entryIdProp: true,
  prefetch: ['es-US'],
  spread: true,
}

const mapAdditionalProps = entry => ({
  noResultsText: entry.noResult,
  description: entry.description,
  searchboxPlaceHolder:
    entry.searchPlaceholder || DEFAULT_SEARCHBOX_PLACEHOLDER,
  searchboxTitle: entry.searchTitle || DEFAULT_SEARCHBOX_TITLE,
})

const mapStateToProps = state => ({
  userSegmentationInfo: userSegmentationBuilder(state),
})

export default ContentEntryDataGetter(
  withRouter(
    withApiError(Container, [
      ERROR_TYPE_CONTENTFUL,
      ERROR_TYPE_CONTENTFUL_PRODUCT,
    ]),
  ),
  options,
  connect(mapStateToProps, NavigationHelper.mapDispatchToProps),
)
