/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useRouteGetter } from 'layers/content/v2/hooks/useRouteGetter'
import { useContentGetter } from 'layers/content/v2/hooks/useContentGetter'
import LoadingParent from 'components/LoadingParent'
import { RouteContentProductAppMap } from 'utils/productMaps'

/**
 * HOC that wraps a component and provides route and content data.
 *
 * @param {React.Component} Component - The component to be wrapped.
 * @param {Object} options - The options for configuring the HOC.
 * @param {Function} options.routeResolver - Optional function that resolves the route URL and takes React-Router `match` as param.
 * @param {Object|Function} options.app - string or function that expresses the app.
 * @returns {React.Component} - The wrapped component with route content data.
 */
export function RouteContentGetter(Component, options) {
  function defaultRouteResolver(match) {
    return match.url
  }

  function RouteContentGetterComponent(props) {
    const { match, locale: initialLocale } = props
    const { routeResolver = defaultRouteResolver, app } = options
    const [locale, setLocale] = useState(initialLocale || 'en-US')

    function changeLocale(newLocale) {
      setLocale(newLocale)
    }

    const appsToRemoveProductSegment = [
      RouteContentProductAppMap.learnHsProduct,
      RouteContentProductAppMap.learnMsProduct,
    ]
    const route = routeResolver(match)
    const resolvedApp = typeof app === 'function' ? app({ route }) : app
    const resolvedRoute =
      appsToRemoveProductSegment.indexOf(resolvedApp) > -1
        ? route.replace('/product/', '/')
        : route

    const {
      data: routeData,
      loading: routeLoading,
      error: routeError,
    } = useRouteGetter({
      ...options,
      app: resolvedApp,
      route: resolvedRoute,
    })

    const {
      data: content,
      loading: contentLoading,
      error: contentError,
      fetch: fetchContent,
    } = useContentGetter({
      ...options,
      lazy: true,
      locale,
      entryId: routeData?.entryId,
      queryType: routeData?.queryType,
    })

    useEffect(() => {
      const shouldFetchContent =
        !!routeData?.entryId &&
        !!routeData?.queryType &&
        (!content || contentError)

      if (shouldFetchContent) {
        fetchContent()
      }
    }, [routeData, locale])

    // Tracks changes in parent locale (a.k.a initialLocale) to update internal locale
    useEffect(() => {
      if (initialLocale && initialLocale !== locale) {
        changeLocale(initialLocale)
      }
    }, [initialLocale])

    const error = routeError || contentError
    const routeContent = {
      route: routeData,
      content: content || {},
      loading: routeLoading || contentLoading || (!error && !content),
      error,
      changeLocale,
      locale,
    }
    if (!!routeContent?.loading && !error) {
      return <LoadingParent loadingText={'Loading...'} />
    }
    return <Component {...props} routeContent={routeContent} />
  }

  RouteContentGetterComponent.propTypes = {
    locale: PropTypes.string,
    match: PropTypes.shape({
      url: PropTypes.string,
    }),
  }

  return RouteContentGetterComponent
}

export const RouteContentType = contentType =>
  PropTypes.shape({
    route: PropTypes.shape({
      entryId: PropTypes.string,
      queryType: PropTypes.string,
    }),
    content: contentType,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    changeLocale: PropTypes.func,
    locale: PropTypes.string,
  })
