/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  AI_HS_PAGE_USAGE_KEY,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelpers'
import { RouteContentGetter } from 'layers/content/v2/Hocs/RouteContentGetter'
import withApiError from 'layers/errorHandling/apiError'
import { RouteContentProductAppMap } from 'utils/productMaps'
import { isPreviewMode } from 'utils/highschoolHelpers'

import Week from './component'

const Container = props => {
  const { userSegmentationInfo, routeContent = {} } = props
  const { content, loading } = routeContent

  const readyToLoad = !loading

  useEffect(() => {
    sendApplicationInsightsEvent(AI_HS_PAGE_USAGE_KEY, userSegmentationInfo)
  }, [userSegmentationInfo])

  return (
    readyToLoad && (
      <Week {...props} content={content} isPreview={isPreviewMode()} />
    )
  )
}

const options = {
  app: RouteContentProductAppMap.learnHsProduct,
  routeResolver: () => window?.location?.pathname,
}

Container.propTypes = {
  routeContent: PropTypes.shape({
    content: PropTypes.object,
    loading: PropTypes.bool,
  }),
  userSegmentationInfo: PropTypes.shape({
    archetypes: PropTypes.array,
    claims: PropTypes.array,
    roles: PropTypes.array,
  }),
}

export default RouteContentGetter(withApiError(Container), options)
