// SelaProductContent.jsx
import React from 'react'
import PropTypes from 'prop-types'
import {
  Content,
  SelaTilesContainer,
  SelaModuleWrapper,
  SelaLoadingDiv,
  Hairline,
} from './component.styles.js'
import ModuleResourceCard from 'components/ModuleResourceCard'
import ModuleCard from 'components/ModuleCard'
import { toUrlSafe } from 'utils/stringHelpers'
import { LOADING_SELA_MESSAGE } from './constants'

const SelaProductContent = ({
  staticLinksAvailable,
  staticLinksAvailableExist,
  lmsPath,
  lmsPathExist,
  contentfulModules,
  internalTitle,
  onComponentClick,
}) => (
  <Content>
    {staticLinksAvailableExist && (
      <SelaTilesContainer>
        {staticLinksAvailable.map((staticLink, index) => {
          const isOnlyLeader =
            staticLink.permissionsRoles?.leader &&
            !staticLink.permissionsRoles?.staff
          return (
            staticLink.displayTitle && (
              <ModuleResourceCard
                dataTestId={`sela-resource-card-${toUrlSafe(
                  staticLink.displayTitle,
                )}`}
                headerTitle={staticLink.displayTitle}
                isOnlyLeader={isOnlyLeader}
                key={`sela-resource-card-${index}`}
                linkLabel={staticLink.linkLabel}
                onClick={() => onComponentClick(staticLink.path)}
              />
            )
          )
        })}
      </SelaTilesContainer>
    )}
    {staticLinksAvailableExist && lmsPathExist && <Hairline />}
    {lmsPathExist &&
      lmsPath.length > 0 &&
      lmsPath[0]?.modules.map((lmsModule, index) => {
        const contentfulModule = contentfulModules.find(
          module => module.id === lmsModule.contentfulID,
        )
        const {
          permissionsRoles,
          label,
          description,
          displayTitle: moduleTitle,
        } = contentfulModule
        const isOnlyLeader =
          permissionsRoles?.leader && !permissionsRoles?.staff
        const {
          attempted,
          completeLessons,
          contentfulID,
          isDone,
          totalLessons,
        } = lmsModule
        return (
          <SelaModuleWrapper key={contentfulID}>
            <ModuleCard
              attempted={attempted}
              completeLessons={completeLessons}
              description={description}
              displayTitle={moduleTitle}
              entryId={contentfulID}
              index={index}
              internalTitle={internalTitle}
              isDone={isDone}
              isOnlyLeader={isOnlyLeader}
              key={`module-card-${index}`}
              label={label}
              onClick={onComponentClick}
              totalLessons={totalLessons}
            />
          </SelaModuleWrapper>
        )
      })}
    {(!lmsPath || lmsPath.length === 0) && (
      <SelaLoadingDiv dataTestId="sela-loading-modules">
        {LOADING_SELA_MESSAGE}
      </SelaLoadingDiv>
    )}
  </Content>
)

SelaProductContent.propTypes = {
  contentfulModules: PropTypes.array.isRequired,
  internalTitle: PropTypes.string,
  lmsPath: PropTypes.array,
  lmsPathExist: PropTypes.bool.isRequired,
  onComponentClick: PropTypes.func.isRequired,
  staticLinksAvailable: PropTypes.array.isRequired,
  staticLinksAvailableExist: PropTypes.bool.isRequired,
}

export default SelaProductContent
