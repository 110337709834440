import React from 'react'
import PropTypes from 'prop-types'
import { FormInputRadioButton, TextLink } from 'secondstep-components'
import { LINK_HREF, LINK_TEXT, STATUS, SUBTITLE1, TITLE } from './constants'

import {
  ButtonAndLabel,
  ButtonSection,
  CancelButton,
  CenteredSubTitle,
  SaveButton,
  SettingName,
  SiteName,
  Status,
  StyledBodyCopy,
  StyledLabel,
  StyledModal,
  StyledRadioButtonsContainer,
  Title,
  TitleSection,
} from './component.styles'

const MsBHUSingleSiteModal = ({
  bhuUnitEnabled,
  handleOnSave,
  onModalCancel,
  setDisabled,
  setEnabled,
  siteName,
}) => {
  return (
    <StyledModal
      dataTestId="MsBHUSingleSite-modal"
      isModalOpen={true}
      modalClose={() => onModalCancel}
    >
      <TitleSection>
        <Title>{TITLE}</Title>
        <CenteredSubTitle>
          <StyledBodyCopy>{SUBTITLE1}</StyledBodyCopy>
          <TextLink href={LINK_HREF} target="_blank">
            {LINK_TEXT}
          </TextLink>
        </CenteredSubTitle>
      </TitleSection>

      <Status>
        <SiteName>{siteName} </SiteName>
        <SettingName>{STATUS}</SettingName>
        <StyledRadioButtonsContainer>
          <ButtonAndLabel>
            <FormInputRadioButton
              answerText=""
              checked={!bhuUnitEnabled}
              id="BHURadioButtonOff"
              name={`single-select-group-off`}
              onChange={() => setDisabled()}
              value="OFF"
            />
            <StyledLabel for="BHURadioButtonOff">OFF</StyledLabel>
          </ButtonAndLabel>

          <ButtonAndLabel>
            <FormInputRadioButton
              answerText=""
              checked={bhuUnitEnabled}
              id="BHURadioButtonOn"
              name={`single-select-group-on`}
              onChange={() => setEnabled()}
              value="ON"
            />
            <StyledLabel for="BHURadioButtonOn">ON</StyledLabel>
          </ButtonAndLabel>
        </StyledRadioButtonsContainer>
      </Status>

      <ButtonSection>
        <CancelButton onClick={() => onModalCancel()}> Cancel </CancelButton>
        <SaveButton onClick={() => handleOnSave()}> Save </SaveButton>
      </ButtonSection>
    </StyledModal>
  )
}

MsBHUSingleSiteModal.propTypes = {
  bhuUnitEnabled: PropTypes.bool.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  onModalCancel: PropTypes.func.isRequired,
  setDisabled: PropTypes.func.isRequired,
  setEnabled: PropTypes.func.isRequired,
  siteName: PropTypes.string.isRequired,
}

export default MsBHUSingleSiteModal
