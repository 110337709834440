import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from '../../layers/navigation/navigationHelper'
import { READ_MORE } from './constants'

import HsTrainingCheckmark from 'components/HsTrainingCheckmark'

import {
  BodyText,
  ContentCalloutContainer,
  ColumnWrapper,
  HeaderText,
  Icon,
  IconContainer,
  TextContent,
  TextLinkContainer,
} from './component.styles'

class ContentCallout extends Component {
  static propTypes = {
    body: PropTypes.string,
    dataTestId: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isComplete: PropTypes.bool,
    // Ensure route prop is defined
    isHighSchoolTrainingPage: PropTypes.bool,
    isHsPreview: PropTypes.bool,
    link: PropTypes.string,
    linkText: PropTypes.string,
    navigationEvent: PropTypes.func,
    route: PropTypes.string,
    title: PropTypes.string,
  }

  navigateToPage = () => {
    const { navigationEvent, link, location } = this.props || {}
    const { pathname, search } = location || {}
    const depth = pathname.split('/').length - 1
    const isDuplicatePath = NavigationHelper.isADuplicatePath(pathname, link)
    if (link && !isDuplicatePath) {
      const fullRoute = NavigationHelper.generateUrlWithDepthForRoute(
        pathname,
        link,
        depth,
      )
      const newRoute = fullRoute + search
      navigationEvent && navigationEvent(newRoute)
    } else {
      console.error('link missing in content callout')
    }
  }

  navigateToHighSchoolTrainingPage = () => {
    const { route } = this.props || {}
    const { origin, search } = window.location || {}
    const fullRoute = `${origin}${route}${search}`
    window.location.href = fullRoute // Use window.location to navigate
  }

  render() {
    const {
      body,
      dataTestId,
      image,
      isComplete,
      linkText = READ_MORE,
      title,
      isHighSchoolTrainingPage,
    } = this.props

    const dataTestIdPrefix = `content-callout`
    const dataTestIdWrapper = `${dataTestIdPrefix}-wrapper-${dataTestId}`
    const dataTestIdHeader = `${dataTestIdPrefix}-header-${dataTestId}`
    const dataTestIdText = `${dataTestIdPrefix}-text-${dataTestId}`
    const dataTestIdLink = `${dataTestIdPrefix}-link-${dataTestId}`

    return (
      <ContentCalloutContainer data-testid={dataTestIdWrapper}>
        <ColumnWrapper>
          <HsTrainingCheckmark isComplete={isComplete} />
          {image && (
            <IconContainer>
              <Icon alt={''} src={image} />
            </IconContainer>
          )}
          <TextContent>
            <HeaderText data-testid={dataTestIdHeader}>{title}</HeaderText>
            <BodyText data-testid={dataTestIdText}>{body}</BodyText>
            <TextLinkContainer
              dataTestId={dataTestIdLink}
              onClick={
                isHighSchoolTrainingPage
                  ? this.navigateToHighSchoolTrainingPage
                  : this.navigateToPage
              }
            >
              {linkText}
            </TextLinkContainer>
          </TextContent>
        </ColumnWrapper>
      </ContentCalloutContainer>
    )
  }
}

export default ContentCallout
