import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { ContainedButton } from 'secondstep-components'

const StartButton = styled(ContainedButton)`
  background: ${themeGet('colors.buttons.startBackground')};
  border-color: ${themeGet('colors.buttons.startBackground')};
  color: ${themeGet('colors.buttons.primaryFont')};

  h5 {
    color: ${themeGet('colors.buttons.primaryFont')};
  }

  &:not([disabled]):hover {
    background: ${themeGet('colors.buttons.startBackgroundHover')};
    border-color: ${themeGet('colors.buttons.startBackgroundHover')};
  }
`

export default StartButton
