import React from 'react'
import { TilesContainer } from './component.styles'
import ResourceAndTrainingCard from 'components/dashboard/ResourceAndTrainingCard'
import { toUrlSafe } from 'utils/stringHelpers'
import PropTypes from 'prop-types'

const FamilyResourceLinks = ({ staticLinksAvailable, onComponentClick }) => {
  return (
    <TilesContainer key="family-resource-links">
      {staticLinksAvailable?.length > 0 &&
        staticLinksAvailable.map((staticLink, index) => {
          const hasVideo = staticLink.internalTitle.includes('video')
          return (
            staticLink.displayTitle && (
              <ResourceAndTrainingCard
                dataTestId={`resource-and-training-card-${toUrlSafe(
                  staticLink.displayTitle,
                )}`}
                headerTitle={staticLink.displayTitle}
                internalTitle={staticLink.internalTitle}
                key={`resource-and-training-card-family-${index}`}
                linkLabel={staticLink.linkLabel}
                onClick={() => onComponentClick(staticLink.path)}
                videoUrl={hasVideo ? staticLink.path : ''}
              />
            )
          )
        })}
    </TilesContainer>
  )
}

FamilyResourceLinks.propTypes = {
  onComponentClick: PropTypes.func,
  staticLinksAvailable: PropTypes.array,
}

export default FamilyResourceLinks
