import React from 'react'
import PropTypes from 'prop-types'
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts'
import AdminDonutTooltip from 'components/AdminDonutTooltip'
import { STUDENT_ACTIVITIES_EMPTY_STATE_MESSAGE } from './constants'
import { validateKeys } from 'utils/validators'
import { programMap } from './programMap'
import {
  ChartWrapper,
  CompletedText,
  CompletedTextAlt,
  DonutWrapper,
  PercentText,
  StudentActivitiesCompleteWrapper,
  StudentActivitiesEmptyMessageWrapper,
  StudentActivitiesEmptyStateWrapper,
  StudentActivitiesText,
  StyledBullet,
  TextWrapper,
  UserCount,
  UserCountBox,
} from './component.styles'

const AdminDonutChart = ({ data, isOptedInStudentActivties, programName }) => {
  if (!data || Object.entries(data).length === 0) {
    return null
  }

  const program = programMap?.[programName]
  const dataIsValid = validateKeys(data, program?.keys)

  if (!dataIsValid) {
    console.error('Data is invalid.')
    return null
  }

  const createCompletedText = (dataSet, programName) => {
    return programName === 'Student Activities'
      ? `${programName} Complete`
      : `**${dataSet?.totalPracticesOrActivitiesCompleted} of ${dataSet?.totalPracticesOrActivities}** ${programName} Complete`
  }

  const createPercentText = (dataSet, programName) => {
    return programName === 'Student Activities'
      ? dataSet?.totalPracticesOrActivitiesCompleted
      : dataSet?.totalPracticesOrActivities === 0
      ? `0%`
      : `${Math.round(
          (dataSet?.totalPracticesOrActivitiesCompleted /
            dataSet?.totalPracticesOrActivities) *
            100,
        )}%`
  }

  let numberOfGradesWithProgress = 0
  const createPieData = (dataSet, programObj) => {
    let pieData = []
    programObj.keys.forEach(key => {
      if (!isStudentActivities) {
        if (key !== 'totalUsers') {
          pieData.push({ name: key, value: dataSet[key] })
        }
      }
      if (isStudentActivities) {
        if (
          key === 'totalPracticesOrActivitiesCompleted' &&
          dataSet[key] === 0
        ) {
          pieData.push({ name: key, value: 1 })
        }
        if (
          key !== 'totalUsers' &&
          key !== 'totalPracticesOrActivitiesCompleted'
        ) {
          if (dataSet[key] > 0) {
            numberOfGradesWithProgress++
          }
          pieData.push({ name: key, value: dataSet[key] })
        }
      }
    })
    if (pieData.length === 2) {
      const indexOfTotalCompleted = pieData.findIndex(
        item => item.name === 'totalPracticesOrActivitiesCompleted',
      )
      pieData = pieData.map(item => {
        if (item.name === 'totalPracticesOrActivities') {
          if (item.value === 0) {
            return { ...item, value: 1 }
          }
          if (item.value > 0) {
            return {
              ...item,
              value: item.value - pieData[indexOfTotalCompleted]?.value,
            }
          }
        } else {
          return item
        }
      })
    }
    return pieData
  }

  const creatUserCountText = (dataSet, programName) => {
    return `**${programName} •** ${dataSet?.totalUsers} Users`
  }

  const isStudentActivities = programName === 'Student Activities'
  const completedText = createCompletedText(data, programName)
  const percentText = createPercentText(data, programName)
  const pieData = createPieData(data, program)
  const userCountText = creatUserCountText(data, programName)
  const blendStroke =
    !isStudentActivities ||
    (isStudentActivities && numberOfGradesWithProgress <= 1)
  const paddingAngle =
    isStudentActivities && numberOfGradesWithProgress > 1 ? 1 : 0

  const totalStudentActivitiesCompleted =
    data?.totalPracticesOrActivitiesCompleted
  const studentActivitiesEmptyMessage = `${totalStudentActivitiesCompleted} Student Activities Complete`
  const hasNoStudentActivitiesProgress = totalStudentActivitiesCompleted === 0
  const shouldRenderStudentActivitiesEmptyState =
    programName === 'Student Activities' &&
    !isOptedInStudentActivties &&
    hasNoStudentActivitiesProgress

  return (
    <ChartWrapper>
      <UserCountBox>
        <UserCount>{userCountText}</UserCount>
      </UserCountBox>
      <DonutWrapper>
        {!shouldRenderStudentActivitiesEmptyState && (
          <TextWrapper>
            <PercentText>{percentText}</PercentText>
            <CompletedText>{completedText}</CompletedText>
          </TextWrapper>
        )}
        {shouldRenderStudentActivitiesEmptyState && (
          <StudentActivitiesEmptyStateWrapper>
            <StudentActivitiesCompleteWrapper>
              <StyledBullet />
              <StudentActivitiesText>
                {studentActivitiesEmptyMessage}
              </StudentActivitiesText>
            </StudentActivitiesCompleteWrapper>
            <StudentActivitiesEmptyMessageWrapper>
              {STUDENT_ACTIVITIES_EMPTY_STATE_MESSAGE}
            </StudentActivitiesEmptyMessageWrapper>
          </StudentActivitiesEmptyStateWrapper>
        )}

        {!shouldRenderStudentActivitiesEmptyState && (
          <ResponsiveContainer aspect={1.5} width="99%">
            <PieChart>
              <Pie
                blendStroke={blendStroke}
                cx="50%"
                cy="50%"
                data={pieData}
                dataKey="value"
                endAngle={-270}
                fill="#8884d8"
                innerRadius="80%"
                outerRadius="97%"
                paddingAngle={paddingAngle}
                startAngle={90}
              >
                {pieData.map((entry, index) => (
                  <Cell
                    fill={program.colors[index % program.colors.length]}
                    key={`cell-${index}`}
                  />
                ))}
              </Pie>
              <Tooltip
                content={
                  <AdminDonutTooltip data={data} programName={programName} />
                }
              />
            </PieChart>
          </ResponsiveContainer>
        )}
      </DonutWrapper>
      {!shouldRenderStudentActivitiesEmptyState && (
        <CompletedTextAlt>{completedText}</CompletedTextAlt>
      )}
    </ChartWrapper>
  )
}

AdminDonutChart.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      totalPracticesOrActivities: PropTypes.number.isRequired,
      totalPracticesOrActivitiesCompleted: PropTypes.number.isRequired,
      totalUsers: PropTypes.number.isRequired,
    }),
    PropTypes.shape({
      grade10LessonsCompleted: PropTypes.number.isRequired,
      grade11LessonsCompleted: PropTypes.number.isRequired,
      grade12LessonsCompleted: PropTypes.number.isRequired,
      grade9LessonsCompleted: PropTypes.number.isRequired,
      totalUsers: PropTypes.number.isRequired,
    }),
  ]).isRequired,
  isOptedInStudentActivties: PropTypes.bool,
  programName: PropTypes.string.isRequired,
}

export default AdminDonutChart
