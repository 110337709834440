import React from 'react'
import {
  HeaderThree,
  AcademicSuccessIcon,
  HandIcon,
} from 'secondstep-components'
import {
  TilesContainer,
  OstTeachLinksWrapper,
  OstStaticLinksWrapper,
} from './component.styles'
import ProgramLinkTile from 'components/dashboard/ProgramLinkTile'
import { SUBHEADER_OST } from './constants'
import PropTypes from 'prop-types'

const OstProductLinks = ({
  headerDataTestId,
  sortedTeachLinks,
  staticLinksAvailable,
}) => {
  return (
    <div>
      <HeaderThree className="program-subheader" dataTestId={headerDataTestId}>
        {SUBHEADER_OST}
      </HeaderThree>

      <TilesContainer key="out-of-school-time-links">
        {sortedTeachLinks?.length > 0 &&
          sortedTeachLinks.map(({ displayTitle, path }, index) => (
            <OstTeachLinksWrapper key={`${displayTitle}-teach-link-${index}`}>
              <ProgramLinkTile displayTitle={displayTitle} path={path} />
            </OstTeachLinksWrapper>
          ))}

        {staticLinksAvailable.map(
          ({ displayTitle, linkLabel, path }, index) => (
            <OstStaticLinksWrapper key={`${displayTitle}-static-link-${index}`}>
              <ProgramLinkTile
                ProgramSupportsIcon={HandIcon}
                ProgramTrainingIcon={AcademicSuccessIcon}
                displayTitle={displayTitle}
                linkLabel={linkLabel}
                path={path}
              />
            </OstStaticLinksWrapper>
          ),
        )}
      </TilesContainer>
    </div>
  )
}

OstProductLinks.propTypes = {
  headerDataTestId: PropTypes.string,
  sortedTeachLinks: PropTypes.array,
  staticLinksAvailable: PropTypes.array.isRequired,
}

export default OstProductLinks
