import ResumeLink from 'src/components/ResumeLink'
import ReviewLink from 'src/components/ReviewLink'
import StartLink from 'src/components/StartLink'
import PreviewLink from 'src/components/PreviewLink'

export default {
  'not attempted': {
    Button: StartLink,
    children: 'Teach',
    dataTestId: 'button-start',
  },
  incomplete: {
    Button: ResumeLink,
    children: 'Resume',
    dataTestId: 'button-resume',
  },
  completed: {
    Button: ReviewLink,
    children: 'Review',
    dataTestId: 'button-review',
  },
  preview: {
    Button: PreviewLink,
    children: 'Preview',
    dataTestId: 'button-preview',
  },
}
